import axios from "axios";
import { LayerGroup } from "react-leaflet";

const cancelToken = axios.CancelToken.source();

const api = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 10000,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const refreshToken = async () => {
  // let refreshToken = await getRefreshToken();
  // const response = await api({
  //   url: `${config.api.url}auth/refresh-token`,
  //   method: 'POST',
  //   data: {'refresh-token': refreshToken},
  //   cancelToken: cancelToken.token,
  // });
  // return response.data;
};

const getToken = async () => {
  const lookForToken = document.cookie.match(/a_token=([^;]*)/);
  return lookForToken[1];
};

api.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${await getToken()}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.log("ERROR: ", error);
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        try {
          const token = await new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          });
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return api(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const data = await refreshToken();
        console.log("+++++++++++ Token refreshed +++++++++++");
        originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
        processQueue(null, data.access_token);
        return api(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default api;
