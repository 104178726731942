import React, { useEffect, useMemo, useState } from "react";
import L, { layerGroup, point } from "leaflet";
import { Marker, Polygon } from "react-leaflet";
import GlobalIcons from "../../SVG-icons/GlobalIcons";

export default function InhibitorRadio({
  sectors,
  center,
  activeSectors,
  selected,
  role,
}) {
  // Helper function to generate points along the rounded base
  const generateRoundedBase = (
    center,
    radius,
    startBearing,
    endBearing,
    numPoints
  ) => {
    const points = [];
    const angleIncrement = (endBearing - startBearing) / (numPoints - 1);

    for (let i = 0; i < numPoints; i++) {
      const bearing = startBearing + i * angleIncrement;
      points.push(calculatePoint(center[0], center[1], radius, bearing));
    }

    return points;
  };

  // Function to calculate a point given distance and bearing
  const calculatePoint = (lat, lng, distance, bearing) => {
    const R = 6371000; // Earth's radius in meters
    const bearingRad = degreesToRadians(bearing);
    const latRad = degreesToRadians(lat);
    const lngRad = degreesToRadians(lng);

    const newLat = Math.asin(
      Math.sin(latRad) * Math.cos(distance / R) +
        Math.cos(latRad) * Math.sin(distance / R) * Math.cos(bearingRad)
    );

    const newLng =
      lngRad +
      Math.atan2(
        Math.sin(bearingRad) * Math.sin(distance / R) * Math.cos(latRad),
        Math.cos(distance / R) - Math.sin(latRad) * Math.sin(newLat)
      );

    return [radiansToDegrees(newLat), radiansToDegrees(newLng)];
  };

  const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;
  const radiansToDegrees = (radians) => (radians * 180) / Math.PI;

  // Function to create segmented triangles
  const createSegmentedTriangles = (data, segments) => {
    const segmentPolygons = [];
    const { lat, lng, range, bearing, amplitude } = data;

    // Calculate start and end bearings for the rounded base
    const startBearing = bearing - amplitude / 2;
    const endBearing = bearing + amplitude / 2;

    // Loop through each segment to create polygons
    for (let i = 0; i < segments; i++) {
      // Calculate the range for the current segment
      const innerRange = (range * i) / segments;
      const outerRange = (range * (i + 1)) / segments;

      // Calculate top and bottom points of the segment
      const innerBasePoints = generateRoundedBase(
        [lat, lng],
        innerRange,
        startBearing,
        endBearing,
        30
      );
      const outerBasePoints = generateRoundedBase(
        [lat, lng],
        outerRange,
        startBearing,
        endBearing,
        30
      );

      // Create the polygon for this segment
      const polygonCoordinates = [
        [lat, lng],
        ...outerBasePoints,
        ...innerBasePoints.reverse(), // Reverse to close the polygon
      ];
      segmentPolygons.push(polygonCoordinates);
    }

    return segmentPolygons;
  };

  const getColor = (sectorId, isActive, toggleColor) => {
    if (isActive) {
      // If sector is active, return the color based on the toggle state
      return toggleColor
        ? { color: "#ffee00", fill: "rgba(255, 238, 0, 0.8)" }
        : { color: "#AAFF00", fill: "rgba(170, 255, 0, 0.8)" };
    }

    // Default color if sector is not active
    if (sectorId % 2 === 0) {
      return { color: "white", fill: "transparent" };
    }
    return { color: "#00FFFF", fill: "transparent" };
  };

  const centerMarker = (position) => {
    let latSum = 0;
    let lngSum = 0;

    position.forEach(([lat, lng]) => {
      latSum += lat;
      lngSum += lng;
    });

    const centerLat = latSum / position.length;
    const centerLng = lngSum / position.length;

    return [centerLat, centerLng];
  };

  // State to manage color toggle
  const [toggleColor, setToggleColor] = useState(true);

  // Effect to change color every 500 milliseconds for active sectors
  useEffect(() => {
    if (activeSectors?.length > 0) {
      const intervalId = setInterval(() => {
        setToggleColor((prev) => !prev); // Toggle the color
      }, 500); // Change color every 500ms

      return () => clearInterval(intervalId); // Clean up on component unmount or when activeSectors changes
    }
  }, [activeSectors]);

  // Memoize the polygons creation for sectors
  const sectorPolygons = useMemo(() => {
    return sectors.map((sector, index) => {
      const isActive = activeSectors?.includes(sector.id);
      const color = getColor(sector.id, isActive, toggleColor);
      const segmentedTriangles = createSegmentedTriangles(
        {
          lat: center[0],
          lng: center[1],
          range: sector.range,
          bearing: sector.heading,
          amplitude: sector.amplitude,
        },
        5 // Number of segments per triangle
      );

      return (
        <>
          {segmentedTriangles.map((polygon, i) => {
            return (
              <React.Fragment key={`${sector.id}-${color.color}-${i}`}>
                <Polygon
                  positions={polygon}
                  fillColor="transparent"
                  pathOptions={{
                    color: color.color,
                    fillColor: color.fill,
                    weight: 1.5,
                  }}
                />
                {(role === "Admin" ||
                  role === "Configurator" ||
                  role === "Mitigation_Operator") &&
                  i === 4 &&
                  selected && (
                    <Marker
                      position={centerMarker(polygon)}
                      icon={
                        new L.Icon({
                          iconUrl: GlobalIcons[`number`](sector.id).url,
                          iconSize: [25, 25],
                          iconAnchor: [12, 20],
                        })
                      }
                    />
                  )}
              </React.Fragment>
            );
          })}
        </>
      );
    });
  }, [sectors, activeSectors, center, role, selected, toggleColor]);

  return <>{sectorPolygons}</>;
}
