const AlertMapUtils = {
  normalizeZones: (data) => {
    let zones = [];
    let zone = [];
    let zoneCordinates = [];
    data.forEach((element, index) => {
      let array = element[0].replace("POLYGON((", "").replace("))", "");
      array = array.split(",");
      zone = [];
      zoneCordinates = [];
      array.forEach((e) => {
        let coor = e.split(" ");
        coor = { lat: Number(coor[0]), lng: Number(coor[1]) };
        let zoneCoor = [coor.lat, coor.lng];
        zoneCordinates.push(zoneCoor);
        zone.push(coor);
      });
      let color, stroke;
      if (element[1] == "friend") {
        // verde
        color = "rgba(15,140,3,0.4)";
        stroke = "#0F8C03";
      }
      if (element[1] == "warning") {
        // azul
        color = "rgba(115,164,245,0.4)";
        stroke = "#03378C";
      }
      if (element[1] == "alert") {
        // rojo
        color = "rgba(245,48,20,0.4)";
        stroke = "#F53014";
      }
      if (element[1] == "neutral") {
        // magenta
        color = "rgba(168,0,158,0.4)";
        stroke = "#a8329e";
      }
      zones.push({
        path: zone,
        fillColor: color,
        strokeColor: stroke,
        id: index,
        zoneCordinates: zoneCordinates,
      });
    });
    return zones;
  },
  normalizeInhibitors: (data) => {
    let inhibLocal = [];
    data.forEach((m) => {
      let row = {
        id: m.inhibitorid,
        name: m.inhibitorname,
        lat: Number(m.latitude),
        lng: Number(m.longitude),
        sectors: m.sectors,
        frequencies: m.frequencies,
        activeSectors: [],
      };
      inhibLocal.push(row);
    });
    return inhibLocal;
  },
  normalizeSensors: (data) => {
    let sensorsLocal = [];
    data.forEach((m) => {
      let row = {
        id: m[0],
        name: m[1],
        lat: Number(m[2]),
        lng: Number(m[3]),
        range: Number(m[4]),
        selected: true,
        radios: [],
        radiosFailCount: 0,
      };
      sensorsLocal.push(row);
    });
    return sensorsLocal;
  },
  bearingDistance: (lat, lon, radius, bearing) => {
    let lat1Rads = toRad(lat);
    let lon1Rads = toRad(lon);
    const R_KM = 6371; // radius in KM
    let d = radius / R_KM; //angular distance on earth's surface

    let bearingRads = toRad(bearing);
    let lat2Rads = Math.asin(
      Math.sin(lat1Rads) * Math.cos(d) +
        Math.cos(lat1Rads) * Math.sin(d) * Math.cos(bearingRads)
    );

    let lon2Rads =
      lon1Rads +
      Math.atan2(
        Math.sin(bearingRads) * Math.sin(d) * Math.cos(lat1Rads),
        Math.cos(d) - Math.sin(lat1Rads) * Math.sin(lat2Rads)
      );

    return {
      latitude: toDeg(lat2Rads),
      longitude: toDeg(lon2Rads),
    };
  },
  toEastPosition: (lat, lng, eastDistance) => {
    let r_earth = 6378;
    var pi = Math.PI;
    var new_longitude =
      lng +
      ((eastDistance / r_earth) * (180 / pi)) / Math.cos((lat * pi) / 180);
    return { lat: Number(lat), lng: Number(new_longitude) };
  },
  toWestPosition: (lat, lng, westDistance) => {
    let r_earth = 6378;
    var pi = Math.PI;
    var new_longitude =
      lng -
      ((westDistance / r_earth) * (180 / pi)) / Math.cos((lat * pi) / 180);
    return { lat: Number(lat), lng: Number(new_longitude) };
  },
};

const toRad = (degrees) => {
  return (degrees * Math.PI) / 180;
};

const toDeg = (radians) => {
  return (radians * 180) / Math.PI;
};

export default AlertMapUtils;
