import api from "./api";

const UserServices = {
  postNewUser: async (body) => {
    const response = await api({
      baseURL: "/services/new/user",
      method: "POST",
      data: { keycloak_realm: body },
    });
    return response;
  },
  getGroupInfo: async () => {
    const response = await api({
      baseURL: "/api/v1/sec-groups",
      method: "GET",
    });
    return response;
  },
  updateProfile: async (body) => {
    const response = await api({
      baseURL: "/services/user",
      method: "POST",
      data: body,
    });
    return response;
  },
};

export default UserServices;
