import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import es from "./locales/es/translation.json";
import ca from "./locales/ca/translation.json";

i18next.use(initReactI18next).init({
  lng: "es",
  fallbackLng: "es",
  resources: {
    es: { translation: es },
    ca: { translation: ca },
  },
  interpolation: {
    escapeValue: false,
  },
  compatibilityJSON: "v3",
});

export default i18next;
