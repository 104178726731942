const InhibitorIcons = {
  selectedInhibIcon: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.36 24.55">
          <defs>
              <style>.cls-1{fill:#e8f3f8;}.cls-1,.cls-2,.cls-3{stroke-width:0px;}.cls-2{fill:orange;}.cls-3{fill:#0077b6;}</style>
          </defs>
          <polygon class="cls-1" points=".38 6.32 .38 18.22 10.68 24.17 20.98 18.22 20.98 6.32 10.68 .37 .38 6.32"/>
          <path class="cls-3" d="m10.68,24.55c-.06,0-.13-.02-.19-.05L.19,18.55c-.12-.07-.19-.19-.19-.33V6.32c0-.13.07-.26.19-.33L10.49.05c.12-.07.26-.07.38,0l10.3,5.95c.12.07.19.19.19.33v11.9c0,.13-.07.26-.19.33l-10.3,5.95c-.06.03-.12.05-.19.05ZM.75,18.01l9.93,5.73,9.93-5.73V6.54L10.68.81.75,6.54v11.46Z"/>
          <path class="cls-2" d="m17.91,12.35c.05-2.57-1.34-4.9-3.61-6.1-.24-.13-.54-.03-.67.21-.06.12-.07.25-.03.38.04.13.13.23.24.29,1.94,1.02,3.12,3.02,3.08,5.21s-1.3,4.14-3.27,5.09c-.17.08-.28.25-.28.44,0,.08.01.15.05.22.06.12.16.21.28.25.12.04.26.04.38-.02h0c2.32-1.11,3.79-3.39,3.84-5.96Z"/>
          <path class="cls-2" d="m3.45,12.2c-.05,2.57,1.34,4.9,3.61,6.1.24.13.54.03.67-.21.06-.12.07-.25.03-.38-.04-.13-.13-.23-.24-.29-1.94-1.02-3.12-3.02-3.08-5.21s1.3-4.14,3.27-5.09c.17-.08.28-.25.28-.44,0-.08-.01-.15-.05-.22-.06-.12-.16-.21-.28-.25-.12-.04-.26-.04-.38.02h0c-2.32,1.11-3.79,3.39-3.84,5.96Z"/>
          <polygon class="cls-2" points="13.96 14.17 10.68 16.07 7.39 14.17 7.39 10.38 10.68 8.48 13.96 10.38 13.96 14.17"/>
      </svg>
      `),
  },
  newInhibIcon: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.36 24.55">
          <defs>
              <style>.cls-1{fill:#e8f3f8;}.cls-1,.cls-2,.cls-3{stroke-width:0px;}.cls-2{fill:#AFE1AF;}.cls-3{fill:#0077b6;}</style>
          </defs>
          <polygon class="cls-1" points=".38 6.32 .38 18.22 10.68 24.17 20.98 18.22 20.98 6.32 10.68 .37 .38 6.32"/>
          <path class="cls-3" d="m10.68,24.55c-.06,0-.13-.02-.19-.05L.19,18.55c-.12-.07-.19-.19-.19-.33V6.32c0-.13.07-.26.19-.33L10.49.05c.12-.07.26-.07.38,0l10.3,5.95c.12.07.19.19.19.33v11.9c0,.13-.07.26-.19.33l-10.3,5.95c-.06.03-.12.05-.19.05ZM.75,18.01l9.93,5.73,9.93-5.73V6.54L10.68.81.75,6.54v11.46Z"/>
          <path class="cls-2" d="m17.91,12.35c.05-2.57-1.34-4.9-3.61-6.1-.24-.13-.54-.03-.67.21-.06.12-.07.25-.03.38.04.13.13.23.24.29,1.94,1.02,3.12,3.02,3.08,5.21s-1.3,4.14-3.27,5.09c-.17.08-.28.25-.28.44,0,.08.01.15.05.22.06.12.16.21.28.25.12.04.26.04.38-.02h0c2.32-1.11,3.79-3.39,3.84-5.96Z"/>
          <path class="cls-2" d="m3.45,12.2c-.05,2.57,1.34,4.9,3.61,6.1.24.13.54.03.67-.21.06-.12.07-.25.03-.38-.04-.13-.13-.23-.24-.29-1.94-1.02-3.12-3.02-3.08-5.21s1.3-4.14,3.27-5.09c.17-.08.28-.25.28-.44,0-.08-.01-.15-.05-.22-.06-.12-.16-.21-.28-.25-.12-.04-.26-.04-.38.02h0c-2.32,1.11-3.79,3.39-3.84,5.96Z"/>
          <polygon class="cls-2" points="13.96 14.17 10.68 16.07 7.39 14.17 7.39 10.38 10.68 8.48 13.96 10.38 13.96 14.17"/>
      </svg>
      `),
  },
  defaultInhibIcon: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.36 24.55">
          <defs>
              <style>.cls-1{fill:#e8f3f8;}.cls-1,.cls-2,.cls-3{stroke-width:0px;}.cls-2{fill:#0177b6;}.cls-3{fill:#0077b6;}</style>
          </defs>
          <polygon class="cls-1" points=".38 6.32 .38 18.22 10.68 24.17 20.98 18.22 20.98 6.32 10.68 .37 .38 6.32"/>
          <path class="cls-3" d="m10.68,24.55c-.06,0-.13-.02-.19-.05L.19,18.55c-.12-.07-.19-.19-.19-.33V6.32c0-.13.07-.26.19-.33L10.49.05c.12-.07.26-.07.38,0l10.3,5.95c.12.07.19.19.19.33v11.9c0,.13-.07.26-.19.33l-10.3,5.95c-.06.03-.12.05-.19.05ZM.75,18.01l9.93,5.73,9.93-5.73V6.54L10.68.81.75,6.54v11.46Z"/>
          <path class="cls-2" d="m17.91,12.35c.05-2.57-1.34-4.9-3.61-6.1-.24-.13-.54-.03-.67.21-.06.12-.07.25-.03.38.04.13.13.23.24.29,1.94,1.02,3.12,3.02,3.08,5.21s-1.3,4.14-3.27,5.09c-.17.08-.28.25-.28.44,0,.08.01.15.05.22.06.12.16.21.28.25.12.04.26.04.38-.02h0c2.32-1.11,3.79-3.39,3.84-5.96Z"/>
          <path class="cls-2" d="m3.45,12.2c-.05,2.57,1.34,4.9,3.61,6.1.24.13.54.03.67-.21.06-.12.07-.25.03-.38-.04-.13-.13-.23-.24-.29-1.94-1.02-3.12-3.02-3.08-5.21s1.3-4.14,3.27-5.09c.17-.08.28-.25.28-.44,0-.08-.01-.15-.05-.22-.06-.12-.16-.21-.28-.25-.12-.04-.26-.04-.38.02h0c-2.32,1.11-3.79,3.39-3.84,5.96Z"/>
          <polygon class="cls-2" points="13.96 14.17 10.68 16.07 7.39 14.17 7.39 10.38 10.68 8.48 13.96 10.38 13.96 14.17"/>
      </svg>
      `),
  },
};

export default InhibitorIcons;
