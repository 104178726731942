import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Flex,
  Table,
  Modal,
  Input,
  Select,
  message,
  Tabs,
  Checkbox,
} from "antd";
import AdminServices from "../services/AdminServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import InhibitorServices from "../services/InhibitorServices";
import SensorsServices from "../services/SensorsServices";
import Header from "../components/Panels/Header";
import ZonesServices from "../services/ZonesServices";
import { useTranslation } from "react-i18next";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";

function SecurityGroups() {
  const { Option } = Select;
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const [userList, setUserList] = useState([]);
  const [sensorList, setSensorList] = useState([]);
  const [inhibitorList, setInhibitorList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [newGroupName, setNewGroupName] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUserModal, setNewUserModal] = useState(false);
  const [newUserData, setNewUserData] = useState({
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    pass: "",
    role: "",
    language: "",
  });
  const [newSensorData, setNewSensorData] = useState({
    id: "",
    name: "",
    lat: "",
    lng: "",
  });
  const [newInhibitorData, setNewInhibitorData] = useState({
    id: "",
    inhibitorname: "",
    lat: 0,
    lng: 0,
    heading: 0,
    frequencies: [""],
    sectors: [{ id: 1, amplitude: 0, heading: 0, range: 0 }],
    manufacturer: "",
  });
  const [newSensorModal, setNewSensorModal] = useState(false);
  const [newInhibitorModal, setNewInhibitorModal] = useState(false);
  const [newUserLoading, setNewUserLoading] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [groupDataModal, setGroupDataModal] = useState({
    group_name: undefined,
    user_list: [],
  });
  const [createGroupLoading, setCreateGroupLoading] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [addSensorModal, setAddSensorModal] = useState(false);
  const [addInhibitorModal, setAddInhibitorModal] = useState(false);
  const [addZoneModal, setAddZoneModal] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedUserToAdd, setSelectedUserToAdd] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  const [modalActiveTab, setModalActiveTab] = useState("1");
  const [groupDataLoading, setGroupDataLoading] = useState(false);
  const [editGroup, setEditGroup] = useState({ name: null });
  const [editUser, setEditUser] = useState({
    name: "",
    firstname: "",
    lastname: "",
    email: "",
    pass: "",
    role: "",
    language: "",
  });
  const [filteredDataSensor, setFilteredDataSensor] = useState([]);
  const [showDuplicatesSensor, setShowDuplicatesSensor] = useState(true);
  const [filteredDataInhibitors, setFilteredDataInhibitors] = useState([]);
  const [showDuplicatesInhibitors, setShowDuplicatesInhibitors] =
    useState(true);
  const [filteredDataZones, setFilteredDataZones] = useState([]);
  const [showDuplicatesZones, setShowDuplicatesZones] = useState(true);
  let buttonRefAddGroup = useRef();
  let buttonRefAddUser = useRef();
  const { TabPane } = Tabs;
  const { t } = useTranslation();

  useEffect(() => {
    getSecurityGroups();
  }, []);

  useEffect(() => {
    const uniqueData = showDuplicatesSensor
      ? sensorList
      : Array.from(
          new Map(sensorList.map((item) => [item.sensorId, item])).values()
        );
    setFilteredDataSensor(uniqueData);
  }, [showDuplicatesSensor]);

  useEffect(() => {
    const uniqueData = showDuplicatesInhibitors
      ? inhibitorList
      : Array.from(
          new Map(
            inhibitorList.map((item) => [item.inhibitorid, item])
          ).values()
        );
    setFilteredDataInhibitors(uniqueData);
  }, [showDuplicatesInhibitors]);

  useEffect(() => {
    const uniqueData = showDuplicatesZones
      ? zoneList
      : Array.from(
          new Map(zoneList.map((item) => [item.zoneId, item])).values()
        );
    setFilteredDataZones(uniqueData);
  }, [showDuplicatesZones]);

  const columns = [
    {
      title: t("Grupo de seguridad"),
      dataIndex: "name",
      key: "name",
      render: (text, item) => (
        <a
          style={{ color: "#707070" }}
          onClick={() => {
            showGroupDataModal(item.id);
          }}
        >
          {text}
        </a>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
      render: (e) => {
        return (
          <>
            {pageLoading ||
              (!userList.some((obj) => obj.group === e.name) &&
                !zoneList.some((obj) => obj.group === e.name) &&
                !sensorList.some((obj) => obj.group === e.name) &&
                !inhibitorList.some((obj) => obj.group === e.name) && (
                  <a
                    onClick={() => deleteGroup(e.id)}
                    style={{ marginRight: 8 }}
                  >
                    <FontAwesomeIcon color="red" icon={faTrash} />
                  </a>
                ))}
            <a onClick={() => setEditGroup({ id: e.id, name: e.name })}>
              <FontAwesomeIcon color="#5F5F5F" icon={faPen} />
            </a>
          </>
        );
      },
    },
  ];

  const userColumn = [
    {
      title: t("Nombre"),
      dataIndex: "name",
      key: "name",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
      render: (e) => {
        return (
          <a onClick={() => unlinkUserWithGroup(e.id)}>
            <FontAwesomeIcon color="red" icon={faTrash} />
          </a>
        );
      },
    },
  ];

  const createTableFilterUsers = () => {
    let filters = [...new Set(groups)].map((group) => ({
      text: group.name,
      value: group.name,
    }));
    filters.push({ text: "Sin grupo", value: null });
    return filters;
  };

  const availableUserColumn = [
    {
      title: t("Nombre de usuario"),
      dataIndex: "name",
      key: "name",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: t("Correo"),
      dataIndex: "email",
      key: "email",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: t("Nombre"),
      dataIndex: "firstname",
      key: "firstname",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: t("Apellido"),
      dataIndex: "lastname",
      key: "lastname",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: t("Grupo"),
      dataIndex: "group",
      key: "group",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
      filters: createTableFilterUsers(),
      onFilter: (value, record) => {
        return record.group === value;
      },
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
      render: (e) => {
        return (
          <>
            {e.group === null && (
              <a onClick={() => deleteUser(e.id)} style={{ marginRight: 8 }}>
                <FontAwesomeIcon color="red" icon={faTrash} />
              </a>
            )}
            <a onClick={() => setEditUser({ ...e, pass: "" })}>
              <FontAwesomeIcon color="#5F5F5F" icon={faPen} />
            </a>
          </>
        );
      },
    },
  ];

  const availableSensorColumns = [
    {
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "4px",
          }}
        >
          <span>{t("Nombre")}</span>
          <Checkbox
            checked={showDuplicatesSensor}
            onChange={() => setShowDuplicatesSensor(!showDuplicatesSensor)}
          >
            {t("Ver grupos")}
          </Checkbox>
        </div>
      ),
      dataIndex: "sensorName",
      key: "sensorName",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Id",
      dataIndex: "sensorId",
      key: "sensorId",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Lat",
      dataIndex: "latitude",
      key: "latitude",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Long",
      dataIndex: "longitude",
      key: "longitude",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: t("Grupo"),
      dataIndex: "group",
      key: "group",
      hidden: !showDuplicatesSensor,
      filters: createTableFilterUsers(),
      onFilter: (value, record) => {
        setShowDuplicatesSensor(true);
        return record.group === value;
      },
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
      render: (e) => {
        return (
          e.group === null && (
            <a onClick={() => deleteSensor(e.sensorId)}>
              <FontAwesomeIcon color="red" icon={faTrash} />
            </a>
          )
        );
      },
    },
  ].filter((column) => !column.hidden);

  const availableInhibitorColumns = [
    {
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "4px",
          }}
        >
          <span>{t("Nombre")}</span>
          <Checkbox
            checked={showDuplicatesInhibitors}
            onChange={() =>
              setShowDuplicatesInhibitors(!showDuplicatesInhibitors)
            }
          >
            {t("Ver grupos")}
          </Checkbox>
        </div>
      ),
      dataIndex: "inhibitorname",
      key: "inhibitorname",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Id",
      dataIndex: "inhibitorid",
      key: "inhibitorid",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Lat",
      dataIndex: "latitude",
      key: "latitude",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Long",
      dataIndex: "longitude",
      key: "longitude",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: t("Grupo"),
      dataIndex: "group",
      key: "group",
      hidden: !showDuplicatesInhibitors,
      filters: createTableFilterUsers(),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
      onFilter: (value, record) => {
        setShowDuplicatesInhibitors(true);
        return record.group === value;
      },
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
      render: (e) => {
        return (
          e.group === null && (
            <a onClick={() => deleteInhibitor(e.inhibitorid)}>
              <FontAwesomeIcon color="red" icon={faTrash} />
            </a>
          )
        );
      },
    },
  ].filter((column) => !column.hidden);

  const availableZoneColumns = [
    {
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "4px",
          }}
        >
          <span>{t("Nombre")}</span>
          <Checkbox
            checked={showDuplicatesZones}
            onChange={() => setShowDuplicatesZones(!showDuplicatesZones)}
          >
            {t("Ver grupos")}
          </Checkbox>
        </div>
      ),
      dataIndex: "zoneName",
      key: "zoneName",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Id",
      dataIndex: "zoneId",
      key: "zoneId",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: t("Tipo de zona"),
      dataIndex: "zoneType",
      key: "zoneType",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: t("Grupo"),
      dataIndex: "group",
      key: "group",
      hidden: !showDuplicatesZones,
      filters: createTableFilterUsers(),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
      onFilter: (value, record) => {
        setShowDuplicatesZones(true);
        return record.group === value;
      },
    },
  ].filter((column) => !column.hidden);

  const sensorColumns = [
    {
      title: t("Nombre"),
      dataIndex: "sensorName",
      key: "sensorName",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Id",
      dataIndex: "sensorId",
      key: "sensorId",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Lat",
      dataIndex: "latitude",
      key: "latitude",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Long",
      dataIndex: "longitude",
      key: "longitude",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
      render: (e) => {
        return (
          <a onClick={() => unlinkSensorWithGroup(e.sensorId)}>
            <FontAwesomeIcon color="red" icon={faTrash} />
          </a>
        );
      },
    },
  ];

  const inhibitorColumns = [
    {
      title: t("Nombre"),
      dataIndex: "inhibitorname",
      key: "inhibitorname",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Id",
      dataIndex: "inhibitorid",
      key: "inhibitorid",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Lat",
      dataIndex: "latitude",
      key: "latitude",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Long",
      dataIndex: "longitude",
      key: "longitude",

      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
      render: (e) => {
        return (
          <a onClick={() => unlinkInhibitorWithGroup(e.inhibitorid)}>
            <FontAwesomeIcon color="red" icon={faTrash} />
          </a>
        );
      },
    },
  ];

  const zoneColumns = [
    {
      title: t("Nombre"),
      dataIndex: "zoneName",
      key: "zoneName",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "Id",
      dataIndex: "zoneId",
      key: "zoneId",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 100,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "white",
          borderRadius: 0,
        },
      }),
      render: (e) => {
        return (
          <a
            onClick={() => {
              unlinkZoneWithGroup(e.zoneId);
            }}
          >
            <FontAwesomeIcon color="red" icon={faTrash} />
          </a>
        );
      },
    },
  ];

  const roles = [
    "Admin",
    "Configurator",
    "Mitigation_Operator",
    "Operator",
    "Viewer",
  ];

  const langauges = ["español", "català"];

  const tabsData = [
    {
      key: "1",
      tab: t("Grupos"),
      content: (
        <Table
          columns={columns}
          dataSource={groups}
          loading={pageLoading}
          style={{ borderRadius: 0 }}
        />
      ),
    },
    {
      key: "2",
      tab: t("Usuarios"),
      content: (
        <Table
          columns={availableUserColumn}
          dataSource={userList}
          loading={pageLoading}
        />
      ),
    },
    {
      key: "3",
      tab: t("Sensores"),
      content: (
        <Table
          columns={availableSensorColumns}
          dataSource={filteredDataSensor}
          loading={pageLoading}
        />
      ),
    },
    {
      key: "4",
      tab: t("Inhibidores"),
      content: (
        <Table
          columns={availableInhibitorColumns}
          dataSource={filteredDataInhibitors}
          loading={pageLoading}
        />
      ),
    },
    {
      key: "5",
      tab: t("Zonas"),
      content: (
        <Table
          columns={availableZoneColumns}
          dataSource={filteredDataZones}
          loading={pageLoading}
        />
      ),
    },
  ];

  const deleteGroup = async (id) => {
    setPageLoading(true);
    try {
      const response = await AdminServices.deleteGroup(id);
      messageApi.open({
        type: "success",
        content: t("Grupo eliminado con exito"),
      });
      getSecurityGroups();
    } catch (error) {
      console.log("deleteGroup error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
      setPageLoading(false);
    }
  };

  const getSecurityGroups = async () => {
    setPageLoading(true);
    try {
      const response = await AdminServices.getAllSecurityGroups();
      if (response.status != 200) {
        navigate("/");
      }
      setGroups(response.data.data);
      getAvailableUsers();
    } catch (error) {
      console.log("getSecurityGroups error: ", error);
      navigate("/");
      setPageLoading(false);
    }
  };

  const getGroupById = async (id) => {
    setGroupDataLoading(true);
    try {
      const response = await AdminServices.getGroupInfoById(id);
      setGroupDataModal(response.data);
    } catch (error) {
      console.log("getGroupById error: ", error);
    } finally {
      setGroupDataLoading(false);
    }
  };

  const getUserList = async () => {
    try {
      const response = await AdminServices.getUserList();
      setUserList(response.data);
      getSensorList();
    } catch (error) {
      console.log("getUserList error: ", error);
      setPageLoading(false);
    }
  };

  const getSensorList = async () => {
    try {
      const response = await AdminServices.getSensorList();
      setSensorList(response.data);
      setFilteredDataSensor(response.data);
      getInhibitorList();
    } catch (error) {
      console.log("getSensorList error: ", error);
      setPageLoading(false);
    }
  };

  const getInhibitorList = async () => {
    try {
      const response = await AdminServices.getInhibitorList();
      setInhibitorList(response.data);
      setFilteredDataInhibitors(response.data);
      getZoneList();
    } catch (error) {
      console.log("getInhibitorList error: ", error);
      setPageLoading(false);
    }
  };

  const getZoneList = async () => {
    try {
      const response = await AdminServices.getZoneList();
      setZoneList(response.data);
      setFilteredDataZones(response.data);
      setPageLoading(false);
    } catch (error) {
      console.log("getZoneList error: ", error);
      setPageLoading(false);
    }
  };

  const getAvailableUsers = async () => {
    try {
      const response = await AdminServices.getAvailableUsers();
      getUserList();
      setAvailableUsers(response.data);
    } catch (error) {
      console.log("getAvailableUsers error: ", error);
      setPageLoading(false);
    }
  };

  const createGroup = async () => {
    setCreateGroupLoading(true);
    try {
      const response = await AdminServices.createGroup({ name: newGroupName });
      setNewGroupName("");
      setIsModalOpen(false);
      getSecurityGroups();
    } catch (error) {
      console.log("createGroup error: ", error);
    } finally {
      setCreateGroupLoading(false);
    }
  };

  const addUserToGroup = async () => {
    try {
      const response = await AdminServices.linkUserWithGroup({
        group_id: groupDataModal.group_id,
        user_id: selectedUserToAdd,
      });
      if (response.data.message == "User already in a group") {
        messageApi.open({
          type: "error",
          content: t("El usuario ya pertenece al grupo"),
        });
      } else {
        messageApi.open({
          type: "success",
          content: t("Usuario agregado al grupo"),
        });
        setAddUserModal(false);
        setSelectedUserToAdd("");
        setShowGroupModal(true);
        getGroupById(groupDataModal.group_id);
        getSecurityGroups();
      }
    } catch (error) {
      console.log("addUserToGroup error: ", error);
    }
  };

  const addSensorToGroup = async () => {
    try {
      const response = await SensorsServices.linkSensorWithGroup({
        group_id: groupDataModal.group_id,
        sensor_id: selectedUserToAdd,
      });
      if (response.data.message == "Link already exists") {
        messageApi.open({
          type: "error",
          content: t("El sensor ya pertenece al grupo"),
        });
      } else {
        messageApi.open({
          type: "success",
          content: t("Sensor agregado al grupo"),
        });
        setAddSensorModal(false);
        setSelectedUserToAdd("");
        setShowGroupModal(true);
        getGroupById(groupDataModal.group_id);
        getSecurityGroups();
        getSensorList();
      }
    } catch (error) {
      console.log("addInhitorToGroup error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
    }
  };
  const addZoneToGroup = async () => {
    try {
      const response = await ZonesServices.linkZoneWithGroup({
        group_id: groupDataModal.group_id,
        zone_id: selectedUserToAdd,
      });
      if (response.data.message == "Link already exists") {
        messageApi.open({
          type: "error",
          content: t("La zona ya pertenece al grupo"),
        });
      } else {
        messageApi.open({
          type: "success",
          content: t("Zona agregado al grupo"),
        });
        setAddZoneModal(false);
        setSelectedUserToAdd("");
        setShowGroupModal(true);
        getGroupById(groupDataModal.group_id);
        getSecurityGroups();
        getSensorList();
      }
    } catch (error) {
      console.log("addInhitorToGroup error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
    }
  };

  const addInhitorToGroup = async () => {
    try {
      const response = await InhibitorServices.linkInhibitorWithGroup({
        group_id: groupDataModal.group_id,
        inhibitor_id: selectedUserToAdd,
      });
      if (response.data.message == "Link already exists") {
        messageApi.open({
          type: "error",
          content: t("El inhibidor ya pertenece al grupo"),
        });
      } else {
        messageApi.open({
          type: "success",
          content: t("Inhibidor agregado al grupo"),
        });
        setAddInhibitorModal(false);
        setSelectedUserToAdd("");
        setShowGroupModal(true);
        getGroupById(groupDataModal.group_id);
        getSecurityGroups();
      }
    } catch (error) {
      console.log("addInhitorToGroup error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
    }
  };

  const handleCreateUser = async () => {
    if (
      newUserData.username === "" ||
      newUserData.email === "" ||
      newUserData.firstname === "" ||
      newUserData.lastname === "" ||
      newUserData.pass === "" ||
      newUserData.role === "" ||
      newUserData.language === ""
    ) {
      messageApi.open({
        type: "error",
        content: t("Complete todos los campos"),
      });
      return;
    }
    setNewUserLoading(true);
    try {
      const response = await AdminServices.createNewUser(newUserData);
      if (response.status == 200) {
        messageApi.open({
          type: "success",
          content: t("Usuario creado con exito"),
        });
        setNewUserModal(false);
        getSecurityGroups();
      }
    } catch (error) {
      console.log("handleCreateUser error: ", error);
      messageApi.open({
        type: "error",
        content: t("Nombre de usuario ya existente"),
      });
    } finally {
      setNewUserLoading(false);
    }
  };

  const modifyUser = async () => {
    if (
      editUser.name === "" ||
      editUser.email === "" ||
      editUser.firstname === "" ||
      editUser.lastname === "" ||
      editUser.role === "" ||
      editUser.language === ""
    ) {
      messageApi.open({
        type: "error",
        content: t("Complete todos los campos"),
      });
      return;
    }
    setNewUserLoading(true);
    try {
      const response = await AdminServices.modifyUser({
        ...editUser,
        username: editUser.name,
      });
      if (response.status == 200) {
        messageApi.open({
          type: "success",
          content: "Usuario modificado con exito",
        });
        setEditUser({
          name: "",
          email: "",
          firstname: "",
          lastname: "",
          pass: "",
          role: "",
          language: "",
        });
        getSecurityGroups();
      }
    } catch (error) {
      console.log("modifyUser error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
    } finally {
      setNewUserLoading(false);
    }
  };

  const deleteUser = async (id) => {
    setPageLoading(true);
    try {
      const response = await AdminServices.deleteUser(id);
      messageApi.open({
        type: "success",
        content: t("Usuario eliminado con exito"),
      });
      getSecurityGroups();
    } catch (error) {
      console.log("deleteUser error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
      setPageLoading(false);
    }
  };

  const modifyGroup = async () => {
    setCreateGroupLoading(true);
    try {
      const response = await AdminServices.modifyGroup({
        name: editGroup.name,
        group_id: editGroup.id,
      });
      setEditGroup({ name: null });
      getSecurityGroups();
    } catch (error) {
      console.log("createGroup error: ", error);
    } finally {
      setCreateGroupLoading(false);
    }
  };

  const unlinkUserWithGroup = async (id) => {
    try {
      const response = await AdminServices.unlinkUserWithGroup(
        id,
        groupDataModal.group_id
      );
      messageApi.open({
        type: "success",
        content: t("Usuario desvinculado con exito"),
      });
      getSecurityGroups();
      getGroupById(groupDataModal.group_id);
    } catch (error) {
      console.log("unlinkUserWithGroup error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
    }
  };

  const deleteSensor = async (id) => {
    setPageLoading(true);
    try {
      const response = await AdminServices.deleteSensor(id);
      messageApi.open({
        type: "success",
        content: t("Sensor eliminado con exito"),
      });
      getSecurityGroups();
    } catch (error) {
      console.log("deleteUser error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
      setPageLoading(false);
    }
  };

  const unlinkSensorWithGroup = async (id) => {
    try {
      const response = await AdminServices.unlinkSensorWithGroup(
        id,
        groupDataModal.group_id
      );
      messageApi.open({
        type: "success",
        content: t("Sensor desvinculado con exito"),
      });
      // setShowGroupModal(false);
      // setGroupDataModal({group_name: undefined, user_list: []})
      getSecurityGroups();
      getGroupById(groupDataModal.group_id);
    } catch (error) {
      console.log("unlinkSensorWithGroup error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
    }
  };

  const unlinkInhibitorWithGroup = async (id) => {
    try {
      const response = await AdminServices.unlinkInhibitorWithGroup(
        id,
        groupDataModal.group_id
      );
      messageApi.open({
        type: "success",
        content: t("Grupo modificado con exito"),
      });
      // setShowGroupModal(false);
      // setGroupDataModal({group_name: undefined, user_list: []})
      getSecurityGroups();
      getGroupById(groupDataModal.group_id);
    } catch (error) {
      console.log("unlinkInhibitorWithGroup error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
    }
  };

  const unlinkZoneWithGroup = async (id) => {
    try {
      const response = await ZonesServices.unlinkZoneWithGroup(
        id,
        groupDataModal.group_id
      );
      messageApi.open({
        type: "success",
        content: t("Grupo modificado con exito"),
      });
      getSecurityGroups();
      getGroupById(groupDataModal.group_id);
    } catch (error) {
      console.log("unlinkZoneWithGroup error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
    }
  };

  const deleteInhibitor = async (id) => {
    setPageLoading(true);
    try {
      const response = await AdminServices.deleteInhibitor(id);
      messageApi.open({
        type: "success",
        content: t("Inhibidor eliminado con exito"),
      });
      getSecurityGroups();
    } catch (error) {
      console.log("deleteUser error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
      setPageLoading(false);
    }
  };

  const createNewSensor = async () => {
    if (
      newSensorData.id === "" ||
      newSensorData.name === "" ||
      newSensorData.lat === "" ||
      newSensorData.lng === ""
    ) {
      messageApi.open({
        type: "error",
        content: t("Complete todos los campos"),
      });
      return;
    }
    setNewUserLoading(true);
    try {
      let body = {
        sensor_id: newSensorData.id,
        manufacturer: "",
        sensor_name: newSensorData.name,
        sensor_state: "active",
        lat: newSensorData.lat,
        long: newSensorData.lng,
        recived_time: new Date().toISOString().slice(0, 19).replace("T", " "),
      };
      const response = await SensorsServices.postSensor(body);
      if (response.status == 200) {
        messageApi.open({
          type: "success",
          content: t("Sensor creado con exito"),
        });
        setNewSensorModal(false);
        setNewSensorData({ id: "", name: "", lat: "", lng: "" });
        getSecurityGroups();
      }
    } catch (error) {
      console.log("hancdleCreateUser error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
    } finally {
      setNewUserLoading(false);
    }
  };

  const createNewInhibitor = async () => {
    if (
      newInhibitorData.id === "" ||
      newInhibitorData.lat === "" ||
      newInhibitorData.lng === "" ||
      newInhibitorData.inhibitorname === ""
    ) {
      messageApi.open({
        type: "error",
        content: t("Complete todos los campos"),
      });
      return;
    }
    setNewUserLoading(true);
    try {
      let body = {
        inhibitorid: newInhibitorData.id,
        inhibitorname: newInhibitorData.inhibitorname,
        latitude: newInhibitorData.lat,
        longitude: newInhibitorData.lng,
        heading: 0,
        frequencies: newInhibitorData.frequencies,
        sectors: newInhibitorData.sectors,
        manufacturer: newInhibitorData.manufacturer,
      };
      const response = await InhibitorServices.postInhibitors(body);
      if (response.status == 200) {
        messageApi.open({
          type: "success",
          content: t("Inhibidor creado con exito"),
        });
        setNewInhibitorModal(false);
        setNewInhibitorData({
          id: "",
          inhibitorname: "",
          lat: 0,
          lng: 0,
          heading: 0,
          frequencies: [""],
          sectors: [{ id: 1, amplitude: 0, heading: 0, range: 0 }],
          manufacturer: "",
        });
        getSecurityGroups();
      }
    } catch (error) {
      console.log("hancdleCreateUser error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
    } finally {
      setNewUserLoading(false);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    createGroup();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showGroupDataModal = (id) => {
    setShowGroupModal(true);
    getGroupById(id);
  };

  const handleAddMemberModal = () => {
    setShowGroupModal(false);
    setAddUserModal(true);
  };

  const handleAddInhibitorModal = () => {
    setShowGroupModal(false);
    setAddInhibitorModal(true);
  };

  const handleAddSensorModal = () => {
    setShowGroupModal(false);
    setAddSensorModal(true);
  };

  const handleAddZoneModal = () => {
    setShowGroupModal(false);
    setAddZoneModal(true);
  };

  const handleAddGroupMember = () => {
    addUserToGroup();
  };

  const handleAddSensorToGroup = () => {
    addSensorToGroup();
  };

  const handleAddZoneToGroup = () => {
    addZoneToGroup();
  };

  const handleAddInhibitorToGroup = () => {
    addInhitorToGroup();
  };

  const handleCancelGroupDataModal = () => {
    setShowGroupModal(false);
    setGroupDataModal({ group_name: undefined, user_list: [] });
  };

  const closeAddModal = () => {
    setShowGroupModal(true);
    setAddUserModal(false);
    setAddInhibitorModal(false);
    setAddSensorModal(false);
    setAddZoneModal(false);
  };

  const handleSelectUserToAdd = (item) => {
    setSelectedUserToAdd(item);
  };

  const handleChangeUserData = (key, value) => {
    const userData = { ...newUserData };
    userData[key] = value;
    setNewUserData(userData);
  };

  const handleChangeEditUserData = (key, value) => {
    const userData = { ...editUser };
    userData[key] = value;
    setEditUser(userData);
  };

  const handleChangeSensorData = (key, value) => {
    const sensorData = { ...newSensorData };
    sensorData[key] = value;
    setNewSensorData(sensorData);
  };

  const modifyInhibData = (key, value) => {
    let inhib = { ...newInhibitorData };
    inhib[key] = value;
    setNewInhibitorData(inhib);
  };

  const modifyInhibFreq = (key, index, value) => {
    let inhib = { ...newInhibitorData };
    inhib[key][index] = value;
    setNewInhibitorData(inhib);
  };

  const modifyInhibSector = (key, index, value) => {
    let inhib = { ...newInhibitorData };
    inhib["sectors"][index][key] = value != "" ? parseInt(value) : "";
    setNewInhibitorData(inhib);
  };

  const inhibAddFreq = () => {
    let inhib = { ...newInhibitorData };
    inhib["frequencies"].push("");
    setNewInhibitorData(inhib);
  };

  const inhibAddSector = () => {
    let inhib = { ...newInhibitorData };
    inhib["sectors"].push({
      id: inhib.sectors.length + 1,
      amplitude: 0,
      heading: 0,
      range: 0,
    });
    setNewInhibitorData(inhib);
  };

  const handleCancelCreateUser = () => {
    setNewUserModal(false);
    setNewUserData({
      username: "",
      firstname: "",
      lastname: "",
      email: "",
      pass: "",
      role: "",
      language: "",
    });
  };

  const handleCancelCreateSensor = () => {
    setNewSensorModal(false);
    setNewSensorData({ id: "", name: "", lat: "", lng: "" });
  };

  const handleCancelCreateInhib = () => {
    setNewInhibitorModal(false);
    setNewInhibitorData({
      id: "",
      inhibitorname: "",
      lat: 0,
      lng: 0,
      heading: 0,
      frequencies: [""],
      sectors: [{ id: 1, amplitude: 0, heading: 0, range: 0 }],
      manufacturer: "",
    });
  };

  return (
    <div style={{ backgroundColor: "#DFDFDF", height: "120vh" }}>
      <Header />
      <div style={{}}>
        {contextHolder}
        <div
          style={{
            height: 48,
            backgroundColor: "white",
            marginTop: 16,
            marginLeft: 60,
            marginRight: 60,
            display: "flex",
            flexDirection: "row",
            paddingLeft: 42,
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon icon={faUser} color="red" size="lg" />
          <p
            style={{
              marginLeft: 5,
              fontSize: 25,
            }}
          >
            {t("Usuarios y grupos")}
          </p>
        </div>
        <Flex
          align="center"
          gap="middle"
          style={{ paddingTop: 8, paddingLeft: 60 }}
        >
          <Button
            ref={(ref) => (buttonRefAddGroup = ref)}
            onMouseEnter={() => {
              if (buttonRefAddGroup) {
                buttonRefAddGroup.style.backgroundColor = "black";
                buttonRefAddGroup.style.color = "white";
              }
            }}
            onMouseLeave={() => {
              if (buttonRefAddGroup) {
                buttonRefAddGroup.style.backgroundColor = "#FFFFFF";
                buttonRefAddGroup.style.color = "black";
              }
            }}
            type="primary"
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: 5,
              borderWidth: 1,
              borderColor: "black",
              color: "black",
              fontSize: 18,
              marginRight: 20,
            }}
            onClick={() => showModal()}
          >
            {t("Crear grupo de seguridad")}
          </Button>
          <Button
            ref={(ref) => (buttonRefAddUser = ref)}
            onMouseEnter={() => {
              if (buttonRefAddUser) {
                buttonRefAddUser.style.backgroundColor = "black";
                buttonRefAddUser.style.color = "white";
              }
            }}
            onMouseLeave={() => {
              if (buttonRefAddUser) {
                buttonRefAddUser.style.backgroundColor = "#FFFFFF";
                buttonRefAddUser.style.color = "black";
              }
            }}
            type="primary"
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: 5,
              borderWidth: 1,
              borderColor: "black",
              color: "black",
              fontSize: 18,
              marginRight: 20,
            }}
            onClick={() => setNewUserModal(true)}
          >
            {t("Crear usuario")}
          </Button>
        </Flex>
        <Tabs
          activeKey={activeKey}
          onChange={setActiveKey}
          indicator={{ size: 0 }}
          tabBarStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            marginBottom: 0,
            height: "60px",
            paddingLeft: 60,
          }}
          items={tabsData.map(({ key, tab, content }) => ({
            key: key,
            label: (
              <div
                style={{
                  paddingLeft: 25,
                  paddingRight: 25,
                  borderTopRightRadius: 5,
                  borderTopLeftRadius: 5,
                  background:
                    activeKey == key
                      ? "white"
                      : "transparent linear-gradient(180deg, #2A2A2A 0%, #7A7A7A 100%) 0% 0% no-repeat padding-box",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  height: "100%",
                  marginBottom: 0,
                  marginTop: 37,
                }}
              >
                <p
                  style={{
                    color: activeKey == key ? "black" : "white",
                    margin: 0,
                    fontSize: 21,
                  }}
                >
                  {tab}
                </p>
              </div>
            ),
            children: content,
          }))}
        />

        {/*MODAL DETALLES DEL GRUPO DE SEGURIDAD */}
        <Modal
          width={"90%"}
          title={
            groupDataModal.group_name === undefined
              ? ""
              : groupDataModal.group_name
          }
          open={showGroupModal}
          okText={
            modalActiveTab == 1
              ? t("Agregar usuario")
              : modalActiveTab == 2
              ? t("Agregar sensor")
              : modalActiveTab == 3
              ? t("Agregar inhibidor")
              : t("Agregar zona")
          }
          onOk={
            modalActiveTab == 1
              ? () => handleAddMemberModal()
              : modalActiveTab == 2
              ? () => handleAddSensorModal()
              : modalActiveTab == 3
              ? () => handleAddInhibitorModal()
              : () => handleAddZoneModal()
          }
          cancelText={t("Cerrar")}
          onCancel={handleCancelGroupDataModal}
          loading={groupDataModal.group_name === undefined || groupDataLoading}
        >
          <Tabs activeKey={modalActiveTab} onChange={setModalActiveTab}>
            <TabPane tab={t("Usuarios")} key="1">
              <Table
                columns={userColumn}
                dataSource={groupDataModal.user_list}
              />
            </TabPane>
            <TabPane tab={t("Sensores")} key="2">
              <Table
                columns={sensorColumns}
                dataSource={groupDataModal.sensors}
              />
            </TabPane>
            <TabPane tab={t("Inhibidores")} key="3">
              <Table
                columns={inhibitorColumns}
                dataSource={groupDataModal.inhibitors}
              />
            </TabPane>
            <TabPane tab={t("Zonas")} key="4">
              <Table columns={zoneColumns} dataSource={groupDataModal.zones} />
            </TabPane>
          </Tabs>
        </Modal>

        {/*MODAL EDITAR GRUPO DE SEGURIDAD */}
        <Modal
          title={t("Editar grupo de seguridad")}
          open={editGroup.name != null}
          onOk={() => modifyGroup()}
          okText={t("Editar")}
          confirmLoading={createGroupLoading}
          onCancel={() => setEditGroup({ name: null })}
          cancelText={t("Cancelar")}
        >
          <Input
            placeholder={t("Nombre del grupo")}
            value={editGroup.name}
            onChange={(e) => {
              setEditGroup({ name: e.target.value, id: editGroup.id });
            }}
          />
        </Modal>

        {/* MODAL CREAR USUARIO NUEVO */}
        <Modal
          title={t("Crear usuario nuevo")}
          open={newUserModal}
          okText={t("Crear usuario")}
          onOk={handleCreateUser}
          okButtonProps={{ disabled: newUserLoading }}
          cancelText={t("Cerrar")}
          onCancel={handleCancelCreateUser}
          loading={false}
        >
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Username")}
            value={newUserData.username}
            onChange={(e) => {
              handleChangeUserData("username", e.target.value);
            }}
          />
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Nombre del usuario")}
            value={newUserData.firstname}
            onChange={(e) => {
              handleChangeUserData("firstname", e.target.value);
            }}
          />
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Apellido del usuario")}
            value={newUserData.lastname}
            onChange={(e) => {
              handleChangeUserData("lastname", e.target.value);
            }}
          />
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Correo del usuario")}
            value={newUserData.email}
            onChange={(e) => {
              handleChangeUserData("email", e.target.value);
            }}
          />
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Contraseña del usuario")}
            value={newUserData.pass}
            onChange={(e) => {
              handleChangeUserData("pass", e.target.value);
            }}
          />
          <Select
            placeholder={t("Selecciona un rol")}
            style={{ width: "100%", marginBottom: 8 }}
            value={newUserData.role || undefined}
            onChange={(e) => {
              handleChangeUserData("role", e);
            }}
          >
            {roles.map((item) => (
              <Option key={item} value={item}>
                {t(item)}
              </Option>
            ))}
          </Select>
          <Select
            placeholder={t("Selecciona un idoma")}
            style={{ width: "100%" }}
            value={newUserData.language || undefined}
            onChange={(e) => {
              handleChangeUserData("language", e);
            }}
          >
            {langauges.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Modal>

        {/* MODAL EDITAR USUARIO */}
        <Modal
          title={t("Editar usuario")}
          open={editUser.name !== ""}
          okText={t("Editar usuario")}
          onOk={modifyUser}
          okButtonProps={{ disabled: newUserLoading }}
          cancelText={t("Cerrar")}
          onCancel={() => {
            setEditUser({
              name: "",
              email: "",
              firstname: "",
              lastname: "",
              pass: "",
              role: "",
              language: "",
            });
          }}
          loading={false}
        >
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Username")}
            value={editUser.name}
            disabled
            onChange={(e) => {
              handleChangeEditUserData("name", e.target.value);
            }}
          />
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Nombre del usuario")}
            value={editUser.firstname}
            onChange={(e) => {
              handleChangeEditUserData("firstname", e.target.value);
            }}
          />
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Apellido del usuario")}
            value={editUser.lastname}
            onChange={(e) => {
              handleChangeEditUserData("lastname", e.target.value);
            }}
          />
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Correo del usuario")}
            value={editUser.email}
            disabled
            onChange={(e) => {
              handleChangeEditUserData("email", e.target.value);
            }}
          />
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Contraseña del usuario")}
            value={editUser.pass}
            onChange={(e) => {
              handleChangeEditUserData("pass", e.target.value);
            }}
          />
          <Select
            placeholder={t("Selecciona un rol")}
            style={{ width: "100%", marginBottom: 8 }}
            value={editUser.role}
            onChange={(e) => {
              handleChangeEditUserData("role", e);
            }}
          >
            {roles.map((item) => (
              <Option key={item} value={item}>
                {t(item)}
              </Option>
            ))}
          </Select>
          <Select
            placeholder={t("Selecciona un idoma")}
            style={{ width: "100%" }}
            value={editUser.language}
            onChange={(e) => {
              handleChangeEditUserData("language", e);
            }}
          >
            {langauges.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Modal>

        {/* MODAL CREAR SENSOR NUEVO */}
        <Modal
          title={"Crear sensor nuevo"}
          open={newSensorModal}
          okText={"Crear sensor"}
          onOk={createNewSensor}
          okButtonProps={{ disabled: newUserLoading }}
          cancelText={"Cerrar"}
          onCancel={handleCancelCreateSensor}
          loading={false}
        >
          <Input
            style={{ marginBottom: 8 }}
            placeholder="Id"
            value={newSensorData.id}
            onChange={(e) => {
              handleChangeSensorData("id", e.target.value);
            }}
          />
          <Input
            style={{ marginBottom: 8 }}
            placeholder="Nombre"
            value={newSensorData.name}
            onChange={(e) => {
              handleChangeSensorData("name", e.target.value);
            }}
          />
          <Input
            type="number"
            style={{ marginBottom: 8 }}
            placeholder="Latitud"
            value={newSensorData.lat}
            onChange={(e) => {
              handleChangeSensorData("lat", e.target.value);
            }}
          />
          <Input
            type="number"
            style={{ marginBottom: 8 }}
            placeholder="Longitud"
            value={newSensorData.lng}
            onChange={(e) => {
              handleChangeSensorData("lng", e.target.value);
            }}
          />
        </Modal>

        {/* MODAL CREAR INHIBIDOR NUEVO */}
        <Modal
          title={"Crear inhibidor nuevo"}
          style={{ maxHeight: "800px", overflowY: "auto" }}
          open={newInhibitorModal}
          okText={"Crear sensor"}
          onOk={createNewInhibitor}
          okButtonProps={{ disabled: newUserLoading }}
          cancelText={"Cerrar"}
          onCancel={handleCancelCreateInhib}
          loading={false}
        >
          <Input
            placeholder="Nombre del inhibidor"
            style={{ marginBottom: 8 }}
            value={newInhibitorData.inhibitorname}
            onChange={(e) => {
              modifyInhibData("inhibitorname", e.target.value);
            }}
          />
          <Input
            placeholder="UUID del inhibidor"
            style={{ marginBottom: 8 }}
            value={newInhibitorData.id}
            onChange={(e) => {
              modifyInhibData("id", e.target.value);
            }}
          />
          <Input
            placeholder="Latitud"
            type="number"
            style={{ marginBottom: 8 }}
            value={newInhibitorData.lat}
            onChange={(e) => {
              modifyInhibData("lat", e.target.value);
            }}
          />
          <Input
            placeholder="Longitud"
            type="number"
            style={{ marginBottom: 8 }}
            value={newInhibitorData.lng}
            onChange={(e) => {
              modifyInhibData("lng", e.target.value);
            }}
          />
          <p
            style={{
              marginBottom: "4px",
              color: "#03045E",
              fontWeight: "bold",
            }}
          >
            Frecuencias{" "}
            <Button
              style={{ height: 16, width: 8 }}
              type="primary"
              onClick={() => {
                inhibAddFreq();
              }}
            >
              {"+"}
            </Button>
          </p>
          {newInhibitorData.frequencies.map((e, index) => (
            <Input
              key={index}
              style={{ marginBottom: 4 }}
              placeholder="Frecuencia"
              value={e}
              onChange={(e) => {
                modifyInhibFreq("frequencies", index, e.target.value);
              }}
            />
          ))}
          <p
            style={{
              marginBottom: "4px",
              color: "#03045E",
              fontWeight: "bold",
            }}
          >
            Sectores{" "}
            <Button
              style={{ height: 16, width: 8 }}
              type="primary"
              onClick={() => {
                inhibAddSector();
              }}
            >
              {"+"}
            </Button>
          </p>
          {newInhibitorData.sectors.map((e, index) => (
            <div>
              <p
                style={{
                  marginBottom: "4px",
                  color: "#03045E",
                  fontWeight: "normal",
                  fontSize: 14,
                }}
              >
                {`Sector ${index + 1}`}
              </p>
              <div>
                <p
                  style={{
                    marginBottom: "4px",
                    color: "#03045E",
                    fontWeight: "normal",
                    fontSize: 12,
                  }}
                >
                  Amplitud
                </p>
                <Input
                  key={index + "amp"} // Don't forget to add a key for each item in a list
                  style={{ marginBottom: 4 }}
                  placeholder="Amplitud"
                  value={e.amplitude}
                  onChange={(e) => {
                    modifyInhibSector("amplitude", index, e.target.value);
                  }}
                />
                <p
                  style={{
                    marginBottom: "4px",
                    color: "#03045E",
                    fontWeight: "normal",
                    fontSize: 12,
                  }}
                >
                  Heading
                </p>
                <Input
                  key={index + "head"} // Don't forget to add a key for each item in a list
                  style={{ marginBottom: 4 }}
                  placeholder="Heading"
                  value={e.heading}
                  onChange={(e) => {
                    modifyInhibSector("heading", index, e.target.value);
                  }}
                />
                <p
                  style={{
                    marginBottom: "4px",
                    color: "#03045E",
                    fontWeight: "normal",
                    fontSize: 12,
                  }}
                >
                  Rango
                </p>
                <Input
                  key={index + "rng"} // Don't forget to add a key for each item in a list
                  style={{ marginBottom: 4 }}
                  placeholder="Rango"
                  value={e.range}
                  onChange={(e) => {
                    modifyInhibSector("range", index, e.target.value);
                  }}
                />
              </div>
            </div>
          ))}
        </Modal>

        {/*MODAL CREAR GRUPO DE SEGURIDAD */}
        <Modal
          title={t("Crear grupo de seguridad")}
          open={isModalOpen}
          onOk={handleOk}
          okText={t("Crear")}
          confirmLoading={createGroupLoading}
          okButtonProps={{ disabled: newGroupName === "" }}
          onCancel={handleCancel}
          cancelText={t("Cancelar")}
        >
          <Input
            placeholder={t("Nombre del grupo")}
            value={newGroupName}
            onChange={(e) => {
              setNewGroupName(e.target.value);
            }}
          />
        </Modal>

        {/* MODAL AGREGAR SENSOR AL GRUPO */}
        <Modal
          title={
            groupDataModal.group_name === undefined
              ? ""
              : `${t("Agregar sensor")}: ` + groupDataModal.group_name
          }
          open={addSensorModal}
          okText={t("Agregar sensor")}
          onOk={handleAddSensorToGroup}
          okButtonProps={{ disabled: selectedUserToAdd === "" }}
          cancelText={t("Cerrar")}
          onCancel={closeAddModal}
          loading={groupDataModal.group_name === undefined}
        >
          <Select
            placeholder={t("Selecciona un sensor")}
            style={{ width: "100%" }}
            onChange={handleSelectUserToAdd}
            value={selectedUserToAdd}
          >
            {Array.from(
              new Map(sensorList.map((item) => [item.sensorId, item])).values()
            ).map((s) => {
              if (
                addSensorModal &&
                !groupDataModal.sensors.some(
                  (item) => item.sensorId === s.sensorId
                )
              ) {
                return (
                  <Option key={s.sensorId} value={s.sensorId}>
                    {`${s.sensorName} (${s.sensorId})`}
                  </Option>
                );
              }
            })}
          </Select>
        </Modal>

        {/* MODAL AGREGAR INHIBIDOR AL GRUPO */}
        <Modal
          title={
            groupDataModal.group_name === undefined
              ? ""
              : `${t("Agregar inhibidor")}: ` + groupDataModal.group_name
          }
          open={addInhibitorModal}
          okText={t("Agregar inhibidor")}
          onOk={handleAddInhibitorToGroup}
          okButtonProps={{ disabled: selectedUserToAdd === "" }}
          cancelText={t("Cerrar")}
          onCancel={closeAddModal}
          loading={groupDataModal.group_name === undefined}
        >
          <Select
            placeholder={t("Selecciona un inhibidor")}
            style={{ width: "100%" }}
            onChange={handleSelectUserToAdd}
            value={selectedUserToAdd}
          >
            {Array.from(
              new Map(
                inhibitorList.map((item) => [item.inhibitorid, item])
              ).values()
            ).map((inh) => {
              if (
                addInhibitorModal &&
                !groupDataModal.inhibitors.some(
                  (item) => item.inhibitorid === inh.inhibitorid
                )
              ) {
                return (
                  <Option key={inh.inhibitorid} value={inh.inhibitorid}>
                    {`${inh.inhibitorname} (${inh.inhibitorid})`}
                  </Option>
                );
              }
            })}
          </Select>
        </Modal>

        {/* MODAL AGREGAR USUARIO AL GRUPO */}
        <Modal
          title={
            groupDataModal.group_name === undefined
              ? ""
              : `${t("Agregar usuario")}:  ` + groupDataModal.group_name
          }
          open={addUserModal}
          okText={t("Agregar usuario")}
          onOk={handleAddGroupMember}
          okButtonProps={{ disabled: selectedUserToAdd === "" }}
          cancelText={t("Cerrar")}
          onCancel={closeAddModal}
          loading={groupDataModal.group_name === undefined}
        >
          <Select
            placeholder={t("Selecciona un usuario")}
            style={{ width: 200 }}
            onChange={handleSelectUserToAdd}
          >
            {availableUsers.map((user) => (
              <Option key={user.id} value={user.id}>
                {user.name}
              </Option>
            ))}
          </Select>
        </Modal>

        {/* MODAL AGREGAR ZONA AL GRUPO */}
        <Modal
          title={
            groupDataModal.group_name === undefined
              ? ""
              : `${t("Agregar zona")}: ` + groupDataModal.group_name
          }
          open={addZoneModal}
          okText={t("Agregar zona")}
          onOk={handleAddZoneToGroup}
          okButtonProps={{ disabled: selectedUserToAdd === "" }}
          cancelText={t("Cerrar")}
          onCancel={closeAddModal}
          loading={groupDataModal.group_name === undefined}
        >
          <Select
            placeholder={t("Selecciona una zona")}
            style={{ width: "100%" }}
            onChange={handleSelectUserToAdd}
            value={selectedUserToAdd}
          >
            {Array.from(
              new Map(zoneList.map((item) => [item.zoneId, item])).values()
            ).map((s) => {
              if (
                addZoneModal &&
                !groupDataModal.zones.some((item) => item.zoneId === s.zoneId)
              ) {
                return (
                  <Option key={s.zoneId} value={s.zoneId}>
                    {`${s.zoneName} (${s.zoneType})`}
                  </Option>
                );
              }
            })}
          </Select>
        </Modal>
      </div>
    </div>
  );
}

export default SecurityGroups;
