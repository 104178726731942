import React, { useEffect, useState } from "react";
import { Button, Input, message, Select } from "antd";
import Header from "../../components/Panels/Header";
import { useAuth } from "../../providers/AuthContex";
import {
  LayersControl,
  MapContainer,
  Marker,
  TileLayer,
  useMapEvents,
  Polygon as Zone,
} from "react-leaflet";
import L from "leaflet";
import GlobalIcons from "../../components/SVG-icons/GlobalIcons";
import { useTranslation } from "react-i18next";
import UserServices from "../../services/UserServices";
import SensorIcons from "../../components/SVG-icons/SensorIcons";
import InhibitorIcons from "../../components/SVG-icons/InhibitorIcons";
import AdminServices from "../../services/AdminServices";
import AlertMapUtils from "../../components/utils/AlertMapUtils";
import ZonesServices from "../../services/ZonesServices";

export default function Profile() {
  const { Option } = Select;
  const { group, saveGroup } = useAuth();
  const { BaseLayer } = LayersControl;
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [position, setPosition] = useState([
    41.37318481141028, 2.1531474024100135,
  ]);
  const [userData, setUserData] = useState({
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    pass: "",
    role: "",
    language: "",
  });
  const [loading, setLoading] = useState(false);
  const [zoom, setZoom] = useState(13);
  const [zones, setZones] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const langauges = ["español", "català"];

  useEffect(() => {
    console.log(group);
    if (group) {
      getZones();
      setPosition(group.map_center);
      setUserData({
        username: group.user_name,
        firstname: group.firstname,
        lastname: group.lastname,
        email: group.email,
        pass: "",
        role: group.roles[0],
        language: group.language,
      });
    }
  }, []);

  const getZones = async () => {
    try {
      const response = await ZonesServices.getZonesByGroup();
      setZones(AlertMapUtils.normalizeZones(response.data));
    } catch (error) {
      console.log("getZones error: ", error);
    }
  };

  const onMarkerDrag = (event) => {
    let eventData = event.target;
    let newPos = [eventData.getLatLng().lat, eventData.getLatLng().lng];
    setPosition(newPos);
  };

  const defaultCenter = {
    lat: 41.37318481141028,
    lng: 2.1531474024100135,
    type: "home",
  };

  const saveCenter = async () => {
    setLoading(true);
    try {
      const body = {
        center_lat: position[0],
        center_lng: position[1],
        zoom: zoom,
      };
      const response = await UserServices.updateProfile(body);
      getGroupInfo();
      messageApi.open({
        type: "success",
        content: "Usuario modificado con exito",
      });
    } catch (error) {
      console.log("saveCenter error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
    } finally {
      setLoading(false);
    }
  };

  const modifyUser = async () => {
    setLoading(true);
    try {
      const response = await AdminServices.modifyUser({
        ...userData,
        id: group.user_id,
      });
      if (response.status == 200) {
        messageApi.open({
          type: "success",
          content: "Usuario modificado con exito",
        });
        getGroupInfo();
      }
    } catch (error) {
      console.log("modifyUser error: ", error);
      messageApi.open({
        type: "error",
        content: t("Algo salio mal intentelo luego"),
      });
    } finally {
      setLoading(false);
    }
  };

  const getGroupInfo = async () => {
    try {
      const response = await UserServices.getGroupInfo();
      if (response.data.language == "español") {
        i18n.changeLanguage("es");
      } else {
        i18n.changeLanguage("ca");
      }
      saveGroup(response.data);
    } catch (error) {
      console.log("getGroupInfo login error: ", error);
    }
  };

  const handleChangeUserData = (key, value) => {
    const data = { ...userData };
    data[key] = value;
    setUserData(data);
  };

  const ZoomListener = () => {
    useMapEvents({
      zoom: (e) => {
        setZoom(e.target.getZoom());
      },
    });
    return null;
  };

  return (
    <div>
      {contextHolder}
      <Header />
      <div
        style={{ padding: 24, display: "flex", flexDirection: "row", gap: 20 }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ color: "#03045E", fontWeight: "bold", fontSize: 14 }}>
            {t("Nombre de usuario")}: {""}
          </span>
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Nombre de usuario")}
            value={group ? group.user_name : ""}
            disabled
          />

          <span style={{ color: "#03045E", fontWeight: "bold", fontSize: 14 }}>
            {t("Nombre")}: {""}
          </span>
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Nombre")}
            value={userData.firstname}
            onChange={(e) => {
              handleChangeUserData("firstname", e.target.value);
            }}
          />

          <span style={{ color: "#03045E", fontWeight: "bold", fontSize: 14 }}>
            {t("Apellido")}: {""}
          </span>
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Apellido")}
            value={userData.lastname}
            onChange={(e) => {
              handleChangeUserData("lastname", e.target.value);
            }}
          />

          <span style={{ color: "#03045E", fontWeight: "bold", fontSize: 14 }}>
            {t("Correo")}: {""}
          </span>
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Correo")}
            value={group ? group.email : ""}
            disabled
          />
          <span style={{ color: "#03045E", fontWeight: "bold", fontSize: 14 }}>
            {t("Contraseña del usuario")}: {""}
          </span>
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t("Contraseña del usuario")}
            value={userData.pass}
            onChange={(e) => {
              handleChangeUserData("pass", e.target.value);
            }}
          />
          <span style={{ color: "#03045E", fontWeight: "bold", fontSize: 14 }}>
            {t("Idioma")}: {""}
          </span>
          <Select
            placeholder={t("Selecciona un idoma")}
            value={userData.language}
            onChange={(e) => {
              handleChangeUserData("language", e);
            }}
          >
            {langauges.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: 5,
              borderWidth: 1,
              borderColor: "black",
              color: "black",
              fontSize: 18,
              marginTop: 16,
            }}
            disabled={loading}
            loading={loading}
            onClick={() => modifyUser()}
          >
            {t("Guardar")}
          </Button>
        </div>
        <div style={{ height: "80vh", width: "90%" }}>
          <span style={{ color: "#03045E", fontWeight: "bold", fontSize: 14 }}>
            {t("Centro del mapa")}:
          </span>
          <div style={{ width: "100%", height: "100%" }}>
            <MapContainer
              center={
                group && group.map_center
                  ? group.map_center
                  : [defaultCenter.lat, defaultCenter.lng]
              }
              zoom={group ? group.zoom : 13}
              style={{ height: "100%", width: "100%" }}
            >
              <LayersControl position="topright">
                <BaseLayer name="Politico">
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                </BaseLayer>
                <BaseLayer checked name="Satelite">
                  <TileLayer
                    url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=AIzaSyCtVcbzEZJCQkDml1iKhhhqvUEpdGLAiSU"
                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                    attribution="&copy; Google"
                  />
                </BaseLayer>
              </LayersControl>
              <ZoomListener />
              <Marker
                key={1}
                position={position}
                icon={new L.Icon({ iconUrl: GlobalIcons.circle.url })}
                draggable={true}
                eventHandlers={{
                  dragend: (event) => onMarkerDrag(event),
                }}
              />
              {group &&
                group.sensors.map((element) => {
                  return (
                    <Marker
                      key={element.id}
                      position={[element.latitude, element.longitude]}
                      icon={
                        new L.Icon({
                          iconUrl: SensorIcons.defaultSensorIcon.url,
                          iconSize: [28, 28],
                          iconAnchor: [12, 20],
                        })
                      }
                    />
                  );
                })}
              {group &&
                group.inhibitors.map((element) => {
                  return (
                    <Marker
                      key={element.id}
                      position={[element.latitude, element.longitude]}
                      icon={
                        new L.Icon({
                          iconUrl: InhibitorIcons.defaultInhibIcon.url,
                          iconSize: [28, 28],
                          iconAnchor: [12, 20],
                        })
                      }
                    />
                  );
                })}
              {group &&
                zones.map((element) => {
                  return (
                    <Zone
                      key={element.id} // Make sure to add a key if this is a list
                      pathOptions={{
                        color: element.strokeColor,
                        fillColor: element.fillColor,
                      }}
                      positions={element.zoneCordinates}
                    />
                  );
                })}
            </MapContainer>
          </div>
          <Button
            type="primary"
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: 5,
              borderWidth: 1,
              borderColor: "black",
              color: "black",
              fontSize: 18,
              marginTop: 16,
            }}
            disabled={loading}
            loading={loading}
            onClick={() => saveCenter()}
          >
            {t("Guardar centro y zoom")}
          </Button>
        </div>
      </div>
    </div>
  );
}
