import React from "react";
import { Button, Input, Select } from "antd";
import SensorsServices from "../../services/SensorsServices";
import { useAuth } from "../../providers/AuthContex";
import { useTranslation } from "react-i18next";

export default function MapEditorSidepanel({
  onAddSensor,
  onAddSensorLizheng,
  selectedSensor,
  onModifySensor,
  onCreateNewSensor,
  onUpdateSensor,
  onDeleteSensor,
  selectedInhib,
  onAddInhib,
  onModifyInhib,
  onCreateNewInhib,
  onUpdateInhib,
  onDeleteInhib,
  sensorButton,
  inhibButton,
  onGetDedroneSensors,
  onGetDedroneZones,
  loading,
}) {
  const { t } = useTranslation();
  const { group } = useAuth();
  const { Option } = Select;
  const frequencies = [
    "400 MHz",
    "868 MHz",
    "915 MHz",
    "2.4 MHz",
    "5.2 MHz",
    "5.5 MHz",
    "5.8 MHz",
    "GNSS (L1/L2/L5)",
  ];

  const modifySensorData = (key, value) => {
    let sensor = { ...selectedSensor };
    sensor[key] = value;
    onModifySensor(sensor);
  };

  const modifyInhibData = (key, value) => {
    let inhib = { ...selectedInhib };
    inhib[key] = value;
    onModifyInhib(inhib);
  };

  const modifyInhibFreq = (key, index, value) => {
    let inhib = { ...selectedInhib };
    inhib[key][index] = value;
    onModifyInhib(inhib);
  };

  const modifyInhibSector = (key, index, value) => {
    let inhib = { ...selectedInhib };
    inhib["sectors"][index][key] = value != "" ? parseInt(value) : "";
    onModifyInhib(inhib);
  };

  const inhibAddFreq = () => {
    let inhib = { ...selectedInhib };
    inhib["frequencies"].push("");
    onModifyInhib(inhib);
  };

  const inhibRemoveFreq = () => {
    let inhib = { ...selectedInhib };
    inhib["frequencies"].pop();
    onModifyInhib(inhib);
  };

  const inhibAddSector = () => {
    let inhib = { ...selectedInhib };
    inhib["sectors"].push({
      id: inhib.sectors.length + 1,
      amplitude: 0,
      heading: 0,
      range: 0,
    });
    onModifyInhib(inhib);
  };

  const inhibRemoveSector = () => {
    let inhib = { ...selectedInhib };
    inhib["sectors"].pop();
    onModifyInhib(inhib);
  };

  return (
    <div style={{ flex: 1, height: "95vh", padding: 4 }}>
      <div>
        <Button
          type="primary"
          style={{
            backgroundColor: "black",
            borderRadius: 5,
            borderWidth: 1,
            borderColor: "#FFFFFF",
            color: "#FFFFFF",
          }}
          onClick={() => onAddSensor()}
          loading={loading}
          disabled={sensorButton || inhibButton || loading}
        >
          {t("+ sensor")}
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: "black",
            borderRadius: 5,
            borderWidth: 1,
            borderColor: "#FFFFFF",
            color: "#FFFFFF",
            marginLeft: 8,
          }}
          onClick={() => onAddSensorLizheng()}
          loading={loading}
          disabled={sensorButton || inhibButton || loading}
        >
          {`${t("+ sensor")} Liezheng`}
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: "black",
            borderRadius: 5,
            borderWidth: 1,
            borderColor: "#FFFFFF",
            color: "#FFFFFF",
            marginTop: 8,
          }}
          onClick={() => onAddInhib()}
          loading={loading}
          disabled={sensorButton || inhibButton || loading}
        >
          {t("+ inhibidor")}
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: "black",
            borderRadius: 5,
            borderWidth: 1,
            borderColor: "#FFFFFF",
            color: "#FFFFFF",
            marginTop: 8,
          }}
          onClick={() => onGetDedroneSensors()}
          loading={loading}
          disabled={sensorButton || inhibButton || loading}
        >
          {t("Agregar sensores de Dedrone")}
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: "black",
            borderRadius: 5,
            borderWidth: 1,
            borderColor: "#FFFFFF",
            color: "#FFFFFF",
            marginTop: 8,
          }}
          onClick={() => onGetDedroneZones()}
          loading={loading}
          disabled={sensorButton || inhibButton || loading}
        >
          {t("Agregar zonas de Dedrone")}
        </Button>
      </div>
      <div
        style={{
          marginTop: 16,
          height: "calc(95vh - 60px)",
          overflowY: "auto",
        }}
      >
        {" "}
        {/* Adjust height as needed */}
        {selectedSensor != null && (
          <div style={{ marginBottom: 16 }}>
            <p
              style={{
                marginBottom: "4px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {t("Nombre del sensor")}
            </p>
            <Input
              placeholder={t("Nombre del sensor")}
              value={selectedSensor.name}
              onChange={(e) => {
                modifySensorData("name", e.target.value);
              }}
            />
            <p
              style={{
                marginBottom: "4px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {t("UUID del sensor")}
            </p>
            <Input
              placeholder={t("UUID del sensor")}
              value={selectedSensor.id}
              disabled={!selectedSensor.new}
              onChange={(e) => {
                modifySensorData("newId", e.target.value);
              }}
            />
            <p
              style={{
                marginBottom: "4px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {t("Latitud")}
            </p>
            <Input
              placeholder={t("Latitud")}
              value={selectedSensor.position.lat}
              onChange={(e) => {
                modifySensorData("lat", e.target.value);
              }}
            />
            <p
              style={{
                marginBottom: "4px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {t("Longitud")}
            </p>
            <Input
              placeholder={t("Longitud")}
              value={selectedSensor.position.lng}
              onChange={(e) => {
                modifySensorData("lng", e.target.value);
              }}
            />
            <p
              style={{
                marginBottom: "4px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {t("Rango (metros)")}
            </p>
            <Input
              placeholder={t("Rango (metros)")}
              value={selectedSensor.range}
              onChange={(e) => {
                modifySensorData("range", e.target.value);
              }}
            />
            <Button
              type="primary"
              loading={loading}
              style={{
                backgroundColor: "black",
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#FFFFFF",
                color: "#FFFFFF",
                marginTop: 8,
                marginRight: 8,
              }}
              disabled={selectedSensor.name === "" || selectedSensor.id === ""}
              onClick={() => {
                selectedSensor.new ? onCreateNewSensor() : onUpdateSensor();
              }}
            >
              {selectedSensor.new ? t("Crear sensor") : t("Guardar sensor")}
            </Button>
            <Button
              type="primary"
              loading={loading}
              style={{
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#e5310e",
                color: "#e5310e",
                backgroundColor: "#FFFFFF",
                marginTop: 8,
              }}
              onClick={() => {
                onDeleteSensor();
              }}
            >
              {t("Borrar sensor")}
            </Button>
          </div>
        )}
        {selectedInhib != null && (
          <div style={{ marginBottom: 16 }}>
            <p
              style={{
                marginBottom: "4px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {t("Nombre del inhibidor")}
            </p>
            <Input
              placeholder={t("Nombre del inhibidor")}
              value={selectedInhib.inhibitorname}
              onChange={(e) => {
                modifyInhibData("inhibitorname", e.target.value);
              }}
            />
            <p
              style={{
                marginBottom: "4px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {t("UUID del inhibidor")}
            </p>
            <Input
              placeholder={t("UUID del inhibidor")}
              value={selectedInhib.id}
              disabled={!selectedInhib.new}
              onChange={(e) => {
                modifyInhibData("newId", e.target.value);
              }}
            />
            <p
              style={{
                marginBottom: "4px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {t("Latitud")}
            </p>
            <Input
              placeholder={t("Latitud")}
              value={selectedInhib.position.lat}
              onChange={(e) => {
                modifyInhibData("lat", e.target.value);
              }}
            />
            <p
              style={{
                marginBottom: "4px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {t("Longitud")}
            </p>
            <Input
              placeholder={t("Longitud")}
              value={selectedInhib.position.lng}
              onChange={(e) => {
                modifyInhibData("lng", e.target.value);
              }}
            />
            {/* <p style={{ marginBottom: '4px', color: 'black', fontWeight: 'bold' }}>
                        Heading
                    </p>
                    <Input 
                        placeholder='Heading'
                        value={selectedInhib.heading} 
                        onChange={(e) => { modifyInhibData("heading", e.target.value) }}
                    /> */}
            <p
              style={{
                marginBottom: "4px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {t("Frecuencias")}
              <Button
                style={{
                  height: 16,
                  width: 8,
                  backgroundColor: "black",
                  color: "#FFFFFF",
                }}
                type="primary"
                disabled={selectedInhib.frequencies.length > 7}
                onClick={() => {
                  inhibAddFreq();
                }}
              >
                {"+"}
              </Button>{" "}
              {selectedInhib.frequencies.length > 1 && (
                <Button
                  style={{
                    height: 16,
                    width: 8,
                    backgroundColor: "#FF161E",
                    color: "#FFFFFF",
                  }}
                  type="primary"
                  onClick={() => {
                    inhibRemoveFreq();
                  }}
                >
                  {"-"}
                </Button>
              )}
            </p>
            {selectedInhib.frequencies.map((e, index) => (
              <Select
                placeholder={t("Selecciona una frecuencia")}
                style={{ width: "100%", marginBottom: 8 }}
                onChange={(item) => modifyInhibFreq("frequencies", index, item)}
                value={selectedInhib.frequencies[index]}
              >
                {frequencies
                  .filter((item) => !selectedInhib.frequencies.includes(item))
                  .map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
              </Select>
            ))}
            <p
              style={{
                marginBottom: "4px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              {t("Sectores")}
              <Button
                style={{
                  height: 16,
                  width: 8,
                  backgroundColor: "black",
                  color: "#FFFFFF",
                }}
                type="primary"
                onClick={() => {
                  inhibAddSector();
                }}
              >
                {"+"}
              </Button>{" "}
              {selectedInhib.sectors.length > 1 && (
                <Button
                  style={{
                    height: 16,
                    width: 8,
                    backgroundColor: "#FF161E",
                    color: "#FFFFFF",
                  }}
                  type="primary"
                  onClick={() => {
                    inhibRemoveSector();
                  }}
                >
                  {"-"}
                </Button>
              )}
            </p>
            {selectedInhib.sectors.map((e, index) => (
              <div>
                <p
                  style={{
                    marginBottom: "4px",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: 14,
                  }}
                >
                  {`${t("Sector")} ${index + 1}`}
                </p>
                <div>
                  <p
                    style={{
                      marginBottom: "4px",
                      color: "black",
                      fontWeight: "normal",
                      fontSize: 12,
                    }}
                  >
                    {t(`Amplitud (grados)`)}
                  </p>
                  <Input
                    key={index + "amp"} // Don't forget to add a key for each item in a list
                    style={{ marginBottom: 4 }}
                    placeholder={t(`Amplitud (grados)`)}
                    value={e.amplitude}
                    onChange={(e) => {
                      modifyInhibSector("amplitude", index, e.target.value);
                    }}
                  />
                  <p
                    style={{
                      marginBottom: "4px",
                      color: "black",
                      fontWeight: "normal",
                      fontSize: 12,
                    }}
                  >
                    {t(`Heading (grados respecto al norte)`)}
                  </p>
                  <Input
                    key={index + "head"} // Don't forget to add a key for each item in a list
                    style={{ marginBottom: 4 }}
                    placeholder={t(`Heading (grados respecto al norte)`)}
                    value={e.heading}
                    onChange={(e) => {
                      modifyInhibSector("heading", index, e.target.value);
                    }}
                  />
                  <p
                    style={{
                      marginBottom: "4px",
                      color: "black",
                      fontWeight: "normal",
                      fontSize: 12,
                    }}
                  >
                    {t("Rango (metros)")}
                  </p>
                  <Input
                    key={index + "rng"} // Don't forget to add a key for each item in a list
                    style={{ marginBottom: 4 }}
                    placeholder={t("Rango (metros)")}
                    value={e.range}
                    onChange={(e) => {
                      modifyInhibSector("range", index, e.target.value);
                    }}
                  />
                </div>
              </div>
            ))}
            <Button
              type="primary"
              loading={loading}
              style={{
                backgroundColor: "black",
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#FFFFFF",
                color: "#FFFFFF",
                marginTop: 8,
                marginRight: 8,
              }}
              disabled={
                selectedInhib.inhibitorname === "" || selectedInhib.id === ""
              }
              onClick={() => {
                selectedInhib.new ? onCreateNewInhib() : onUpdateInhib();
              }}
            >
              {selectedInhib.new
                ? t("Crear inhibidor")
                : t("Guardar inhibidor")}
            </Button>
            <Button
              type="primary"
              loading={loading}
              style={{
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#e5310e",
                color: "#e5310e",
                backgroundColor: "#FFFFFF",
                marginTop: 8,
              }}
              onClick={() => {
                onDeleteInhib();
              }}
            >
              {t("Borrar inhibidor")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

// {
//     "inhibitorid": "bd9677e0-c0d1-499e-ab08-2b17dfdac552",
//     "inhibitorname": "test",
//     "latitude": 15.0123,
//     "longitude": 9.023,
//     "heading": 0,
//     "frequencies": ["a", "b"],
//     "sectors": [{"id": 1, "range": 50, "heading": 90, "amplitude": 10}, {"id": 2, "range": 60, "heading": 180, "amplitude": 15}],
//     "manufacturer": "test"
// }
