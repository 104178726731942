import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polygon as Zone,
  LayersControl,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useAuth } from "../../providers/AuthContex.js";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import MapEditorSidepanel from "./MapEditorSidepanel.js";
import SensorsServices from "../../services/SensorsServices.js";
import UserServices from "../../services/UserServices.js";
import InhibitorServices from "../../services/InhibitorServices.js";
import AdminServices from "../../services/AdminServices.js";
import InhibMarker from "./InhibMarker.js";
import SensorIcons from "../SVG-icons/SensorIcons.js";
import ZonesServices from "../../services/ZonesServices.js";
import AlertMapUtils from "../utils/AlertMapUtils.js";
import { useTranslation } from "react-i18next";
import GlobalIcons from "../SVG-icons/GlobalIcons.js";

export default function MapEditorComponent() {
  const { group, saveGroup } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();
  const [sensor, setSensor] = useState([]);
  const [inhibitors, setInhibitors] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [selectedInhib, setSelectedInhib] = useState(null);
  const [loading, setLoading] = useState(false);
  const [zones, setZones] = useState([]);
  const { BaseLayer } = LayersControl;
  const { t } = useTranslation();

  const defaultCenter = {
    lat: 41.37318481141028,
    lng: 2.1531474024100135,
    type: "home",
  };

  useEffect(() => {
    getGroupInfo();
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setSelectedInhib(null);
        setSelectedSensor(null);
        setSensor(sensor.filter((item) => !item.new));
        setInhibitors(inhibitors.filter((item) => !item.new));
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const onError = (message) => {
    messageApi.open({
      type: "error",
      content: message ? message : t("Algo salio mal intentelo luego"),
    });
  };

  const getGroupInfo = async () => {
    try {
      const response = await UserServices.getGroupInfo();
      saveGroup(response.data);
      getSensorsAndInhib();
      getZones();
    } catch (error) {
      console.log("getGroupInfo error: ", error);
      onError();
    }
  };

  const getSensorsAndInhib = async () => {
    try {
      const responseSensor = await AdminServices.getSensorList();
      const responseInhib = await AdminServices.getInhibitorList();
      normalizeSensors(responseSensor.data);
      normalizeInhibitors(responseInhib.data);
    } catch (error) {
      console.log("getSensorsAndInhib error: ", error);
    }
  };

  const getZones = async () => {
    try {
      const response = await ZonesServices.getZones();
      setZones(AlertMapUtils.normalizeZones(response.data));
    } catch (error) {
      console.log("getZones error: ", error);
    }
  };

  const createNewSensor = async (manufacturer) => {
    setLoading(true);
    try {
      let body = {
        sensor_id: selectedSensor.id,
        manufacturer: manufacturer ? manufacturer : "",
        sensor_name: selectedSensor.name,
        sensor_state: "active",
        lat: selectedSensor.position.lat,
        long: selectedSensor.position.lng,
        recived_time: new Date().toISOString().slice(0, 19).replace("T", " "),
        range: selectedSensor.range,
      };
      const response = await SensorsServices.postSensor(body);
      setSelectedSensor(null);
      if (response.status === 200) {
        // let linkBody = {
        //     "group_id": group.group_id,
        //     "sensor_id": selectedSensor.id,
        // }
        // const linkResponse = await SensorsServices.linkSensorWithGroup(linkBody)
        getGroupInfo();
      }
    } catch (error) {
      console.log("createNewSensor error: ", error);
      onError();
    } finally {
      setLoading(false);
    }
  };

  const createNewInhib = async () => {
    setLoading(true);
    try {
      let body = {
        inhibitorid: selectedInhib.id,
        inhibitorname: selectedInhib.inhibitorname,
        latitude: selectedInhib.position.lat,
        longitude: selectedInhib.position.lng,
        heading: selectedInhib.heading,
        frequencies: selectedInhib.frequencies,
        sectors: selectedInhib.sectors,
        manufacturer: selectedInhib.manufacturer,
      };
      const response = await InhibitorServices.postInhibitors(body);
      setSelectedInhib(null);
      if (response.status === 200) {
        // let linkBody = {
        //     "group_id": group.group_id,
        //     "inhibitor_id": selectedInhib.id,
        // }
        // const linkResponse = await InhibitorServices.linkInhibitorWithGroup(linkBody)
        getGroupInfo();
      }
    } catch (error) {
      console.log("createNewInhib error: ", error);
      if (error.response.status === 400) {
        onError("Formato del UUID incorrecto");
      } else {
        onError();
      }
    } finally {
      setLoading(false);
    }
  };

  const updateSensor = async () => {
    setLoading(true);
    try {
      let body = {
        sensor_id: selectedSensor.id,
        manufacturer: selectedSensor.manufacturer,
        sensor_name: selectedSensor.name,
        sensor_state: "active",
        lat: selectedSensor.position.lat,
        long: selectedSensor.position.lng,
        recived_time: new Date().toISOString().slice(0, 19).replace("T", " "),
        range: selectedSensor.range,
      };
      const response = await SensorsServices.putSensor(body);
      setSelectedSensor(null);
      if (response.status === 200) {
        getGroupInfo();
      }
    } catch (error) {
      console.log("modifySensor error: ", error);
      onError();
    } finally {
      setLoading(false);
    }
  };

  const updateInhib = async () => {
    setLoading(true);
    try {
      let body = {
        inhibitorid: selectedInhib.id,
        inhibitorname: selectedInhib.inhibitorname,
        latitude: selectedInhib.position.lat,
        longitude: selectedInhib.position.lng,
        heading: selectedInhib.heading,
        frequencies: selectedInhib.frequencies,
        sectors: selectedInhib.sectors,
        manufacturer: selectedInhib.manufacturer,
      };
      const response = await InhibitorServices.putInhibitors(body);
      console.log(response.status);
      setSelectedInhib(null);
      if (response.status === 200) {
        getGroupInfo();
      }
    } catch (error) {
      console.log("updateInhib error: ", error);
      onError();
    } finally {
      setLoading(false);
    }
  };

  const deleteSensor = async () => {
    if (selectedSensor.new) {
      let sensorsLocal = [...sensor];
      const cleanMarker = sensorsLocal.filter(
        (obj) => obj.id !== selectedSensor.id
      );
      setSensor(cleanMarker);
      setSelectedSensor(null);
      return;
    }

    if (selectedSensor.group != null) {
      onError(
        "El sensor no debe de estar en un grupo para poder ser eliminado"
      );
      return;
    }

    setLoading(true);
    try {
      let body = { sensor_id: selectedSensor.id };
      const response = await SensorsServices.deleteSensor(body);
      console.log("RESPONSE: ", response);
      if (response.status === 200) {
        setSelectedSensor(null);
        getGroupInfo();
      }
    } catch (error) {
      console.log("deleteSensor error: ", error);
      onError();
    } finally {
      setLoading(false);
    }
  };

  const deleteInhib = async () => {
    if (selectedInhib.new) {
      let inhibLocal = [...inhibitors];
      const cleanMarker = inhibLocal.filter(
        (obj) => obj.id !== selectedInhib.id
      );
      setInhibitors(cleanMarker);
      setSelectedInhib(null);
      return;
    }

    if (selectedInhib.group != null) {
      onError(
        "El inhibidor no debe de estar en un grupo para poder ser eliminado"
      );
      return;
    }

    setLoading(true);
    try {
      let body = { inhibitorid: selectedInhib.id };
      const response = await InhibitorServices.deleteInhibitors(body);
      if (response.status === 200) {
        setSelectedInhib(null);
        getGroupInfo();
      }
    } catch (error) {
      console.log("deleteInhib error: ", error);
      onError();
    } finally {
      setLoading(false);
    }
  };

  const getDedroneSensors = async () => {
    setLoading(true);
    try {
      const response = await AdminServices.getDedroneSensors();
      console.log("RESPONSE: ", response);
      setSelectedInhib(null);
      setSelectedSensor(null);
      getGroupInfo();
    } catch (error) {
      console.log("getDedroneSensors error: ", error);
      onError();
    } finally {
      setLoading(false);
    }
  };

  const getDedroneZones = async () => {
    setLoading(true);
    try {
      const response = await AdminServices.getDedroneZones();
      setSelectedInhib(null);
      setSelectedSensor(null);
      getGroupInfo();
    } catch (error) {
      onError();
    } finally {
      setLoading(false);
    }
  };

  const normalizeSensors = (data) => {
    let sensorsLocal = [];
    data.forEach((m) => {
      let row = {
        id: m.sensorId,
        name: m.sensorName,
        position: { lat: Number(m.latitude), lng: Number(m.longitude) },
        new: false,
        group: m.group,
        range: m.range,
        manufacturer: m.sensorSource,
      };
      sensorsLocal.push(row);
    });
    setSensor(
      Array.from(new Map(sensorsLocal.map((item) => [item.id, item])).values())
    );
  };

  const normalizeInhibitors = (data) => {
    let inhibitorLocal = [];
    data.forEach((m) => {
      let row = {
        ...m,
        position: { lat: Number(m.latitude), lng: Number(m.longitude) },
        id: m.inhibitorid,
        new: false,
        group: m.group,
      };
      inhibitorLocal.push(row);
    });

    setInhibitors(
      Array.from(
        new Map(inhibitorLocal.map((item) => [item.id, item])).values()
      )
    );
  };

  const addNewSensor = () => {
    let sensorsLocal = [...sensor];
    const uuid = uuidv4();
    sensorsLocal.push({
      id: uuid,
      name: "",
      position: {
        lat: group ? Number(group.map_center[0]) : Number(41.37318481141028),
        lng: group ? Number(group.map_center[1]) : Number(2.1531474024100135),
      },
      new: true,
    });
    setSelectedInhib(null);
    setSensor(sensorsLocal);
    setSelectedSensor({
      id: uuid,
      name: "",
      position: {
        lat: group ? Number(group.map_center[0]) : Number(41.37318481141028),
        lng: group ? Number(group.map_center[1]) : Number(2.1531474024100135),
      },
      new: true,
    });
  };

  const addNewSensorLizheng = () => {
    let sensorsLocal = [...sensor];
    sensorsLocal.push({
      id: "",
      name: "",
      manufacturer: "Lizheng",
      position: {
        lat: group ? Number(group.map_center[0]) : Number(41.37318481141028),
        lng: group ? Number(group.map_center[1]) : Number(2.1531474024100135),
      },
      new: true,
    });
    setSelectedInhib(null);
    setSensor(sensorsLocal);
    setSelectedSensor({
      id: "",
      name: "",
      manufacturer: "Lizheng",
      position: {
        lat: group ? Number(group.map_center[0]) : Number(41.37318481141028),
        lng: group ? Number(group.map_center[1]) : Number(2.1531474024100135),
      },
      new: true,
    });
  };

  const addNewInhib = () => {
    let inhibLocal = [...inhibitors];
    const uuid = uuidv4();
    inhibLocal.push({
      id: uuid,
      inhibitorname: "",
      position: {
        lat: group ? Number(group.map_center[0]) : Number(41.37318481141028),
        lng: group ? Number(group.map_center[1]) : Number(2.1531474024100135),
      },
      heading: 0,
      frequencies: [""],
      sectors: [{ id: 1, amplitude: 0, heading: 0, range: 0 }],
      manufacturer: "",
      new: true,
    });
    setSelectedSensor(null);
    setSelectedInhib({
      id: uuid,
      inhibitorname: "",
      position: {
        lat: group ? Number(group.map_center[0]) : Number(41.37318481141028),
        lng: group ? Number(group.map_center[1]) : Number(2.1531474024100135),
      },
      heading: 0,
      frequencies: [""],
      sectors: [{ id: 1, amplitude: 0, heading: 0, range: 0 }],
      manufacturer: "",
      new: true,
    });
    setInhibitors(inhibLocal);
  };

  const onSensorDragEnd = (event, markerId) => {
    let eventData = event.target;
    const updatedMarkers = sensor.map((marker) => {
      if (marker.id === markerId) {
        setSelectedSensor({
          ...marker,
          position: {
            lat: eventData.getLatLng().lat,
            lng: eventData.getLatLng().lng,
          },
        });
        return {
          ...marker,
          position: {
            lat: eventData.getLatLng().lat,
            lng: eventData.getLatLng().lng,
          },
        };
      }
      return marker;
    });
    setSensor(updatedMarkers);
  };

  const onInhibDragEnd = (event, markerId) => {
    let eventData = event.target;
    const updatedMarkers = inhibitors.map((marker) => {
      if (marker.id === markerId) {
        setSelectedInhib({
          ...marker,
          position: {
            lat: eventData.getLatLng().lat,
            lng: eventData.getLatLng().lng,
          },
        });
        return {
          ...marker,
          position: {
            lat: eventData.getLatLng().lat,
            lng: eventData.getLatLng().lng,
          },
        };
      }
      return marker;
    });
    setInhibitors(updatedMarkers);
  };

  const modifySensor = (newMarker) => {
    const updatedMarkers = sensor.map((marker) => {
      if (marker.id === newMarker.id) {
        const updatedId =
          newMarker.newId !== null && newMarker.newId !== undefined
            ? newMarker.newId
            : newMarker.id;
        setSelectedSensor({ ...newMarker, id: updatedId });
        return { ...newMarker, id: updatedId };
      }
      return marker;
    });
    setSensor(updatedMarkers);
  };

  const modifyInhib = (newMarker) => {
    const updatedMarkers = inhibitors.map((marker) => {
      if (marker.id === newMarker.id) {
        const updatedId =
          newMarker.newId !== null && newMarker.newId !== undefined
            ? newMarker.newId
            : newMarker.id;
        setSelectedInhib({ ...newMarker, id: updatedId });
        return { ...newMarker, id: updatedId };
      }
      return marker;
    });
    setInhibitors(updatedMarkers);
  };

  const sensorIcon = (element) => {
    if (element.new) {
      return {
        icon: {
          iconUrl: SensorIcons.newSensorIcon.url,
          iconSize: [28, 28],
          iconAnchor: [12, 20],
        },
        layer: 1000000,
      };
    }

    if (selectedSensor !== null && selectedSensor.id === element.id) {
      return {
        icon: {
          iconUrl: SensorIcons.selectedSensorIcon.url,
          iconSize: [28, 28],
          iconAnchor: [12, 20],
        },
        layer: 1000000,
      };
    } else {
      return {
        icon: {
          iconUrl: SensorIcons.defaultSensorIcon.url,
          iconSize: [28, 28],
          iconAnchor: [12, 20],
        },
        layer: 1,
      };
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "95vh",
        }}
      >
        <MapEditorSidepanel
          onAddSensor={() => addNewSensor()}
          onAddSensorLizheng={() => addNewSensorLizheng()}
          selectedSensor={selectedSensor}
          onModifySensor={(i) => modifySensor(i)}
          onCreateNewSensor={(manufacturer) => createNewSensor(manufacturer)}
          onUpdateSensor={() => updateSensor()}
          onDeleteSensor={() => deleteSensor()}
          sensorButton={sensor.some((obj) => obj.new === true)}
          //-------------------------------------
          onAddInhib={() => {
            addNewInhib();
          }}
          selectedInhib={selectedInhib}
          onModifyInhib={(i) => modifyInhib(i)}
          onCreateNewInhib={() => createNewInhib()}
          onUpdateInhib={() => updateInhib()}
          onDeleteInhib={() => deleteInhib()}
          inhibButton={inhibitors.some((obj) => obj.new === true)}
          //-------------------------------------
          onGetDedroneSensors={() => getDedroneSensors()}
          onGetDedroneZones={() => getDedroneZones()}
          loading={loading}
        />
        <div style={{ flex: 3, backgroundColor: "#e9ecef" }}>
          <MapContainer
            center={
              group != null && group.map_center
                ? group.map_center
                : [defaultCenter.lat, defaultCenter.lng]
            }
            zoom={group ? group.zoom : 13}
            style={{ height: "100%", width: "100%" }}
          >
            <LayersControl position="topright">
              <BaseLayer name="Politico">
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
              </BaseLayer>
              <BaseLayer checked name="Satelite">
                <TileLayer
                  url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=AIzaSyCtVcbzEZJCQkDml1iKhhhqvUEpdGLAiSU"
                  subdomains={["mt0", "mt1", "mt2", "mt3"]}
                  attribution="&copy; Google"
                />
              </BaseLayer>
            </LayersControl>
            {sensor.map((element) => {
              const { icon, layer } = sensorIcon(element);
              return (
                <Marker
                  key={element.id}
                  position={[element.position.lat, element.position.lng]}
                  icon={new L.Icon(icon)}
                  draggable={true}
                  eventHandlers={{
                    dragend: (event) => onSensorDragEnd(event, element.id),
                    click: () => {
                      setSelectedInhib(null);
                      setSelectedSensor(element);
                    },
                  }}
                />
              );
            })}
            {inhibitors.map((inhib) =>
              InhibMarker({
                inhib: inhib,
                onSelectedInhib: (inh) => {
                  setSelectedInhib(inh);
                  setSelectedSensor(null);
                },
                onInhibDragEnd: (event, id) => onInhibDragEnd(event, id),
                selectedInhib: selectedInhib,
              })
            )}
            {zones.map((element) => {
              return (
                <Zone
                  key={element.id} // Make sure to add a key if this is a list
                  pathOptions={{
                    color: element.strokeColor,
                    fillColor: element.fillColor,
                  }}
                  positions={element.zoneCordinates}
                />
              );
            })}
          </MapContainer>
        </div>
      </div>
    </div>
  );
}
