const SensorIcons = {
  newSensorIcon: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
                <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.2 24.37">
          <defs>
            <style>.cls-1{fill:#fff3f3;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#AFE1AF;}</style>
          </defs>
          <polygon class="cls-1" points=".38 6.28 .38 18.09 10.6 23.99 20.83 18.09 20.83 6.28 10.6 .38 .38 6.28"/>
          <path class="cls-2" d="m10.6,24.37c-.06,0-.13-.02-.19-.05L.19,18.41c-.12-.07-.19-.19-.19-.32V6.28c0-.13.07-.26.19-.32L10.41.05c.12-.07.26-.07.38,0l10.23,5.9c.12.07.19.19.19.32v11.81c0,.13-.07.26-.19.32l-10.23,5.9c-.06.03-.12.05-.19.05ZM.75,17.87l9.85,5.69,9.85-5.69V6.5L10.6.81.75,6.5v11.38Z"/>
          <path class="cls-2" d="m10.6,4.68c-4.13,0-7.51,3.38-7.51,7.51s3.38,7.51,7.51,7.51,7.51-3.38,7.51-7.51-3.38-7.51-7.51-7.51Zm0,13.66c-3.38,0-6.16-2.78-6.16-6.16s2.78-6.16,6.16-6.16,6.16,2.78,6.16,6.16-2.78,6.16-6.16,6.16Z"/>
          <path class="cls-2" d="m10.6,7.38c-2.63,0-4.8,2.18-4.8,4.8s2.18,4.8,4.8,4.8,4.8-2.18,4.8-4.8-2.18-4.8-4.8-4.8Zm0,8.18c-1.88,0-3.38-1.5-3.38-3.38s1.5-3.38,3.38-3.38,3.38,1.5,3.38,3.38-1.5,3.38-3.38,3.38Z"/>
          <path class="cls-2" d="m10.6,10.16c-1.13,0-2.03.9-2.03,2.03s.9,2.03,2.03,2.03,2.03-.9,2.03-2.03-.9-2.03-2.03-2.03Z"/>
        </svg>
        `),
  },
  selectedSensorIcon: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.2 24.37">
          <defs>
            <style>.cls-1{fill:#fff3f3;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:orange;}</style>
          </defs>
          <polygon class="cls-1" points=".38 6.28 .38 18.09 10.6 23.99 20.83 18.09 20.83 6.28 10.6 .38 .38 6.28"/>
          <path class="cls-2" d="m10.6,24.37c-.06,0-.13-.02-.19-.05L.19,18.41c-.12-.07-.19-.19-.19-.32V6.28c0-.13.07-.26.19-.32L10.41.05c.12-.07.26-.07.38,0l10.23,5.9c.12.07.19.19.19.32v11.81c0,.13-.07.26-.19.32l-10.23,5.9c-.06.03-.12.05-.19.05ZM.75,17.87l9.85,5.69,9.85-5.69V6.5L10.6.81.75,6.5v11.38Z"/>
          <path class="cls-2" d="m10.6,4.68c-4.13,0-7.51,3.38-7.51,7.51s3.38,7.51,7.51,7.51,7.51-3.38,7.51-7.51-3.38-7.51-7.51-7.51Zm0,13.66c-3.38,0-6.16-2.78-6.16-6.16s2.78-6.16,6.16-6.16,6.16,2.78,6.16,6.16-2.78,6.16-6.16,6.16Z"/>
          <path class="cls-2" d="m10.6,7.38c-2.63,0-4.8,2.18-4.8,4.8s2.18,4.8,4.8,4.8,4.8-2.18,4.8-4.8-2.18-4.8-4.8-4.8Zm0,8.18c-1.88,0-3.38-1.5-3.38-3.38s1.5-3.38,3.38-3.38,3.38,1.5,3.38,3.38-1.5,3.38-3.38,3.38Z"/>
          <path class="cls-2" d="m10.6,10.16c-1.13,0-2.03.9-2.03,2.03s.9,2.03,2.03,2.03,2.03-.9,2.03-2.03-.9-2.03-2.03-2.03Z"/>
        </svg>
        `),
  },
  defaultSensorIcon: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.2 24.37">
          <defs>
            <style>.cls-1{fill:#fff3f3;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#0077b6;}</style>
          </defs>
          <polygon class="cls-1" points=".38 6.28 .38 18.09 10.6 23.99 20.83 18.09 20.83 6.28 10.6 .38 .38 6.28"/>
          <path class="cls-2" d="m10.6,24.37c-.06,0-.13-.02-.19-.05L.19,18.41c-.12-.07-.19-.19-.19-.32V6.28c0-.13.07-.26.19-.32L10.41.05c.12-.07.26-.07.38,0l10.23,5.9c.12.07.19.19.19.32v11.81c0,.13-.07.26-.19.32l-10.23,5.9c-.06.03-.12.05-.19.05ZM.75,17.87l9.85,5.69,9.85-5.69V6.5L10.6.81.75,6.5v11.38Z"/>
          <path class="cls-2" d="m10.6,4.68c-4.13,0-7.51,3.38-7.51,7.51s3.38,7.51,7.51,7.51,7.51-3.38,7.51-7.51-3.38-7.51-7.51-7.51Zm0,13.66c-3.38,0-6.16-2.78-6.16-6.16s2.78-6.16,6.16-6.16,6.16,2.78,6.16,6.16-2.78,6.16-6.16,6.16Z"/>
          <path class="cls-2" d="m10.6,7.38c-2.63,0-4.8,2.18-4.8,4.8s2.18,4.8,4.8,4.8,4.8-2.18,4.8-4.8-2.18-4.8-4.8-4.8Zm0,8.18c-1.88,0-3.38-1.5-3.38-3.38s1.5-3.38,3.38-3.38,3.38,1.5,3.38,3.38-1.5,3.38-3.38,3.38Z"/>
          <path class="cls-2" d="m10.6,10.16c-1.13,0-2.03.9-2.03,2.03s.9,2.03,2.03,2.03,2.03-.9,2.03-2.03-.9-2.03-2.03-2.03Z"/>
        </svg>
        `),
  },
  alertSensorIcon: {
    url:
      "data:image/svg+xml;charset=UTF-8," +
      encodeURIComponent(`
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.2 24.37">
          <defs>
            <style>.cls-1{fill:#fff3f3;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:red;}</style>
          </defs>
          <polygon class="cls-1" points=".38 6.28 .38 18.09 10.6 23.99 20.83 18.09 20.83 6.28 10.6 .38 .38 6.28"/>
          <path class="cls-2" d="m10.6,24.37c-.06,0-.13-.02-.19-.05L.19,18.41c-.12-.07-.19-.19-.19-.32V6.28c0-.13.07-.26.19-.32L10.41.05c.12-.07.26-.07.38,0l10.23,5.9c.12.07.19.19.19.32v11.81c0,.13-.07.26-.19.32l-10.23,5.9c-.06.03-.12.05-.19.05ZM.75,17.87l9.85,5.69,9.85-5.69V6.5L10.6.81.75,6.5v11.38Z"/>
          <path class="cls-2" d="m10.6,4.68c-4.13,0-7.51,3.38-7.51,7.51s3.38,7.51,7.51,7.51,7.51-3.38,7.51-7.51-3.38-7.51-7.51-7.51Zm0,13.66c-3.38,0-6.16-2.78-6.16-6.16s2.78-6.16,6.16-6.16,6.16,2.78,6.16,6.16-2.78,6.16-6.16,6.16Z"/>
          <path class="cls-2" d="m10.6,7.38c-2.63,0-4.8,2.18-4.8,4.8s2.18,4.8,4.8,4.8,4.8-2.18,4.8-4.8-2.18-4.8-4.8-4.8Zm0,8.18c-1.88,0-3.38-1.5-3.38-3.38s1.5-3.38,3.38-3.38,3.38,1.5,3.38,3.38-1.5,3.38-3.38,3.38Z"/>
          <path class="cls-2" d="m10.6,10.16c-1.13,0-2.03.9-2.03,2.03s.9,2.03,2.03,2.03,2.03-.9,2.03-2.03-.9-2.03-2.03-2.03Z"/>
        </svg>
        `),
  },
};

export default SensorIcons;
