import React, { useState, useMemo, useCallback } from "react";
import { Button } from "antd";
import GlobalIcons from "../SVG-icons/GlobalIcons";
import { useTranslation } from "react-i18next";
import WebIcons from "../SVG-icons/WebIcons";

const Footer = React.memo(
  ({ layers, onSetLayers, alarmFilter, onSetAlarmFilter }) => {
    const { t } = useTranslation();

    const onLayersChange = (key) => {
      const data = { ...layers };
      data[key] = !data[key];
      onSetLayers(data);
    };

    const onAlarmFilterChange = useCallback(
      (type) => {
        onSetAlarmFilter((prev) => {
          const newFilter = { ...prev };
          newFilter[type] = !prev[type];
          return newFilter;
        });
      },
      [onSetAlarmFilter]
    );

    const AlarmFilterButton = useMemo(
      () =>
        ({ icon, type }) =>
          (
            <div
              onClick={() => onAlarmFilterChange(type)}
              style={{
                backgroundColor: alarmFilter[type] ? "white" : "black",
                marginLeft: 8,
                borderRadius: 5,
                height: 33,
                width: 33,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {icon}
            </div>
          ),
      [alarmFilter, onAlarmFilterChange] // Dependencies for memoization
    );

    return (
      <div
        style={{
          display: "flex",
          padding: "10px",
          justifyContent: "flex-start",
          backgroundColor: "#DFDFDF",
          alignItems: "center",
        }}
      >
        <Button
          type="primary"
          style={{
            backgroundColor: layers.sensors ? "#FFFFFF" : "black",
            borderRadius: 5,
            borderWidth: 1,
            borderColor: "black",
            color: layers.sensors ? "black" : "#FFFFFF",
            fontSize: 18,
            marginRight: 20,
          }}
          onClick={() => onLayersChange("sensors")}
        >
          {t("Mostrar sensores")}
        </Button>
        <Button
          type="primary"
          style={{
            backgroundColor: layers.zones ? "#FFFFFF" : "black",
            borderRadius: 5,
            borderWidth: 1,
            borderColor: "black",
            color: layers.zones ? "black" : "#FFFFFF",
            fontSize: 18,
            marginRight: 20,
          }}
          onClick={() => onLayersChange("zones")}
        >
          {t("Mostrar zonas")}
        </Button>
        <AlarmFilterButton icon={GlobalIcons.neutralIcon()} type={"neutral"} />
        <AlarmFilterButton icon={GlobalIcons.warningIcon()} type={"warning"} />
        <AlarmFilterButton icon={GlobalIcons.alertIcon()} type={"alert"} />
        <AlarmFilterButton
          icon={GlobalIcons.friendlyIcon()}
          type={"friendly"}
        />

        <img
          src={WebIcons.sqvLogoSmall.url}
          style={{
            height: "25px",
            marginLeft: "auto",
          }}
        />
      </div>
    );
  }
);

export default Footer;
