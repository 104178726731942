import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../providers/AuthContex";
import { useKeycloak } from "../../providers/KeycloakContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import logo from "../img/sq-logo.jpeg";
import { useTranslation } from "react-i18next";
import WebIcons from "../SVG-icons/WebIcons";
import GlobalIcons from "../SVG-icons/GlobalIcons";
import alarmAudio from "../sounds/alarm.wav";

function Header({ alert }) {
  const [open, setOpen] = useState(false);
  const { logout, group } = useAuth();
  const keycloak = useKeycloak();
  const { t } = useTranslation();
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [mute, setMute] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio(alarmAudio);
      audioRef.current.loop = true;
    }

    if (alert != undefined && alert != null && alert && !isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    }

    if (alert != undefined && alert != null && !alert && isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, [alert]);

  useEffect(() => {
    if (group === null) {
      navigate("/login");
    }
  }, [group]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const logOut = async () => {
    try {
      logout();
      if (keycloak) {
        await keycloak.logout({});
        console.log("CHAU");
        navigate("/");
      } else {
        console.log("Keycloak instance is not available");
      }
    } catch (error) {
      console.log("logOut error: ", error);
    }
  };

  const SafeBadge = () => {
    return (
      <div
        style={{
          display: "flex",
          borderWidth: 2,
          borderColor: "#0177B6",
          backgroundColor: "#E8F3F8",
          width: 178,
          height: 38,
          // marginLeft: 155,
          justifyContent: "center",
          alignItems: "center",
          gap: 8,
        }}
        onClick={() => {
          if (audioRef.current.volume == 0.0) {
            audioRef.current.volume = 1.0;
            setMute(false);
          } else {
            audioRef.current.volume = 0.0;
            setMute(true);
          }
        }}
      >
        {GlobalIcons.safeBadgeIcon()}
        <span
          style={{
            color: "#0177B6",
            textAlign: "center",
          }}
        >
          SAVE
        </span>
      </div>
    );
  };

  const AlertBadge = () => {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#FF161E",
          width: 178,
          height: 38,
          // marginLeft: 155,
          justifyContent: "center",
          alignItems: "center",
          gap: 8,
        }}
        onClick={() => {
          if (audioRef.current.volume == 0.0) {
            audioRef.current.volume = 1.0;
            setMute(false);
          } else {
            audioRef.current.volume = 0.0;
            setMute(true);
          }
        }}
      >
        {GlobalIcons.alertBadgeIcon()}
        <span
          style={{
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          Alert
        </span>
      </div>
    );
  };

  return (
    <header
      style={{
        display: "flex",
        padding: "10px",
        height: "5vh",
        alignItems: "center",
        backgroundColor: "#00B4D8",
        flexDirection: "row",
        position: "relative",
        background:
          "transparent linear-gradient(0deg, #000000 0%, #5F5F5F 71%, #878787 100%) 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
      }}
    >
      <div
        style={{
          flex: 1.9,
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <img src={WebIcons.sqvLogo.url} style={{ height: "15px" }} />
        {location.pathname === "/" && !alert && <SafeBadge />}
        {location.pathname === "/" && alert && <AlertBadge />}
      </div>
      <div style={{ flex: 3, display: "flex" }}>
        {location.pathname === "/" && (
          <a
            onClick={() => {
              if (audioRef.current.volume == 0.0) {
                audioRef.current.volume = 1.0;
                setMute(false);
              } else {
                audioRef.current.volume = 0.0;
                setMute(true);
              }
            }}
          >
            <FontAwesomeIcon
              icon={!mute ? faVolumeHigh : faVolumeMute}
              color="#FFFFFF"
              style={{ position: "absolute", top: 28, right: 140 }}
              size="sm"
            />
          </a>
        )}
        {group != null &&
          group != undefined &&
          group.user_name != undefined && (
            <a
              style={{
                position: "absolute",
                right: 45,
                top: 25,
                color: "#FFFFFF",
              }}
              onClick={() => {
                navigate("/profile");
              }}
            >
              <FontAwesomeIcon
                icon={faUser}
                color="#FFFFFF"
                size="sm"
                style={{ marginRight: 8, marginLeft: 16 }}
              />
              {group.user_name}
            </a>
          )}
        <div
          onClick={() => {
            showDrawer();
          }}
          style={{ position: "absolute", right: 15, top: 25 }}
        >
          <FontAwesomeIcon icon={faBars} color="#FFFFFF" />
        </div>
      </div>

      <Drawer
        onClose={onClose}
        open={open}
        style={{
          background:
            "transparent linear-gradient(0deg, #2A2A2A 0%, #7A7A7A 100%) 0% 0% no-repeat padding-box",
        }}
        footerStyle={{ padding: 0 }}
        footer={
          <a
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              color: "red",
              backgroundColor: "white",
              height: 55,
            }}
            onClick={() => logOut()}
          >
            <FontAwesomeIcon icon={faSignOutAlt} color="red" size="lg" />
            <p
              style={{
                fontWeight: "bold",
                marginLeft: 5,
                fontSize: 25,
                color: "#D80000",
              }}
            >
              {t("Cerrar sesión")}
            </p>
          </a>
        }
      >
        {group != null &&
          group != undefined &&
          group.user_name != undefined && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  height: "40%",
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {group.roles.includes("Configurator") ||
                  (group.roles.includes("Admin") && (
                    <>
                      <a
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          color:
                            location.pathname === "/adm/security-group"
                              ? "white"
                              : "black",
                          backgroundColor:
                            location.pathname === "/adm/security-group"
                              ? "black"
                              : "white",
                          height: 40,
                          paddingLeft: 42,
                        }}
                        onClick={() => {
                          onClose();
                          navigate("/adm/security-group");
                        }}
                      >
                        <FontAwesomeIcon icon={faUser} color="red" size="lg" />
                        <p
                          style={{
                            fontWeight: "bold",
                            marginLeft: 5,
                            fontSize: 25,
                          }}
                        >
                          {t("Usuarios y grupos")}
                        </p>
                      </a>
                      <div
                        style={{
                          height: 1,
                          width: "100%",
                          backgroundColor: "white",
                        }}
                      />
                    </>
                  ))}
                {group.roles.includes("Configurator") ||
                  (group.roles.includes("Admin") && (
                    <>
                      <a
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          color:
                            location.pathname === "/map-editor"
                              ? "white"
                              : "black",
                          backgroundColor:
                            location.pathname === "/map-editor"
                              ? "black"
                              : "white",
                          height: 40,
                          paddingLeft: 42,
                        }}
                        onClick={() => {
                          onClose();
                          navigate("/map-editor");
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMapLocationDot}
                          color="red"
                          size="lg"
                        />
                        <p
                          style={{
                            fontWeight: "bold",
                            marginLeft: 5,
                            fontSize: 25,
                          }}
                        >
                          {t("Map editor")}
                        </p>
                      </a>
                      <div
                        style={{
                          height: 1,
                          width: "100%",
                          backgroundColor: "white",
                        }}
                      />
                    </>
                  ))}

                <a
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "black",
                    backgroundColor: "white",
                    height: 40,
                    paddingLeft: 42,
                  }}
                >
                  <FontAwesomeIcon icon={faBell} color="red" size="lg" />
                  <p
                    style={{ fontWeight: "bold", marginLeft: 5, fontSize: 25 }}
                  >
                    {t("Alertas")}
                  </p>
                </a>
                <div
                  style={{ height: 1, width: "100%", backgroundColor: "white" }}
                />
                <a
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: "black",
                    backgroundColor: "white",
                    height: 40,
                    paddingLeft: 42,
                  }}
                >
                  <FontAwesomeIcon icon={faFile} color="red" size="lg" />
                  <p
                    style={{ fontWeight: "bold", marginLeft: 5, fontSize: 25 }}
                  >
                    {t("Reportes")}
                  </p>
                </a>
                <div
                  style={{ height: 1, width: "100%", backgroundColor: "white" }}
                />
                <a
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    color: location.pathname === "/" ? "white" : "black",
                    backgroundColor:
                      location.pathname === "/" ? "black" : "white",
                    height: 40,
                    paddingLeft: 42,
                  }}
                  onClick={() => {
                    onClose();
                    navigate("/");
                  }}
                >
                  <FontAwesomeIcon icon={faHome} color="red" size="lg" />
                  <p
                    style={{ fontWeight: "bold", marginLeft: 5, fontSize: 25 }}
                  >
                    {t("Home")}
                  </p>
                </a>
              </div>
            </div>
          )}
      </Drawer>
    </header>
  );
}

export default Header;
